import { Post } from "@app/models/post.model";
import * as PostActions from "./posts-paging.actions"
import { createReducer, on } from "@ngrx/store";
import { PostElement } from "@app/models/post-element.model";

export interface PostPagingState {
    post: Post | null;
    postsByPage: Post[] | null;
    total: number;
    page: number;
    searchText: string;
    firstPost: any;
    lastPost: any;
    posts: Post [] | null;
    elements: PostElement[] | null;
    error: any | null;
    loading: boolean;
}

export const initialState: PostPagingState = {
    post: null,
    postsByPage: null,
    total: 0,
    page: 0,
    searchText: '',
    firstPost: null,
    lastPost: null,
    posts: null,
    elements: null,
    error: null,
    loading: false
}

export const reducer = createReducer(
    initialState,

    on(PostActions.getFirstPosts,
    (state) =>
      ({...state, loading: true, error: null})),
    on(PostActions.getFirstPostsSuccess,
        (state, { total, firstPost, lastPost, postsByPage }) =>
            ({...state, loading: false, total, firstPost, lastPost, postsByPage})),
    on(PostActions.getFirstPostsFailure, (state, { error }) => ({...state, loading: false, error})),

    on(PostActions.getPreviousPosts,
    (state) =>
      ({...state, loading: true, error: null})),
    on(PostActions.getPreviousPostsSuccess,
        (state, { total, firstPost, lastPost, postsByPage }) =>
            ({...state, loading: false, total, page: state.page - 1, firstPost, lastPost, postsByPage})),
    on(PostActions.getPreviousPostsFailure, (state, { error }) => ({...state, loading: false, error})),

    on(PostActions.getNextPosts,
    (state) =>
      ({...state, loading: true, error: null})),
    on(PostActions.getNextPostsSuccess,
        (state, { total, firstPost, lastPost, postsByPage }) =>
            ({...state, loading: false, total, page: state.page + 1,firstPost, lastPost, postsByPage})),
    on(PostActions.getNextPostsFailure, (state, { error }) => ({...state, loading: false, error})),

    on(PostActions.searchPosts,
        (state, {searchText}) =>
            ({...state, searchText: searchText, loading: true, error: null})),
    on(PostActions.searchPostsSuccess,
        (state, { total, firstPost, lastPost, postsByPage }) =>
            ({...state, loading: false, total, page: 0, firstPost, lastPost, postsByPage})),
    on(PostActions.searchPostsFailure, (state, { error }) => ({...state, loading: false, error})),
)
