import { Action } from "@ngrx/store";
import { readDetailRequest, successOrderInfo, updateInventoryRequest } from './quotation.models';

export enum Types {
  GET_QUOTATIONS = '[Quotation] Get Quotations: Start',
  GET_QUOTATIONS_SUCCESS = '[Quotation] Get Quotations: Success',
  GET_QUOTATIONS_ERROR = '[Quotation] Get Quotations: Error',

  GET_QUOTATION_DETAIL = '[Quotation] Get Quotation Detail: Start',
  GET_QUOTATION_DETAIL_SUCCESS = '[Quotation] Get Quotation Detail: Success',
  GET_QUOTATION_DETAIL_ERROR = '[Quotation] Get Quotation Detail: Error',

  SUBMIT_QUOTATION_ORDER = '[Order] Submit Quotation Order: Start',
  SUBMIT_QUOTATION_ORDER_SUCCESS = '[Order] Submit Quotation Order: Success',
  SUBMIT_QUOTATION_ORDER_ERROR = '[Order] Submit Quotation Order: Error',

  IS_NEW_ORDER = '[Order] Set is new order',
  CLEAR_DATA = '[Checkout] Clear checkout data: Start',

  UPDATE_INVENTORY_QUOTATION = '[ Update inventory ] Update inventory: Start',
  UPDATE_INVENTORY_QUOTATION_SUCCESS = '[ Update inventory ] Update inventory: Success',
  UPDATE_INVENTORY_QUOTATION_ERROR = '[ Update inventory ] Update inventory: Error',

  RESET_DATA = '[SignOut] Clear Quotations Data',
}

export class GetQuotations implements Action{
  readonly type = Types.GET_QUOTATIONS;
  constructor(public merchantId: string) {}
}

export class GetQuotationsSuccess implements Action{
  readonly type = Types.GET_QUOTATIONS_SUCCESS;
  constructor(public quotations: any) {}
}

export class GetQuotationsError implements Action{
  readonly type = Types.GET_QUOTATIONS_ERROR;
  constructor(public error: string) {}
}

export class GetQuotationDetail implements Action {
  readonly type = Types.GET_QUOTATION_DETAIL;
  constructor(public request: readDetailRequest) { }
}

export class GetQuotationDetailSuccess implements Action {
  readonly type = Types.GET_QUOTATION_DETAIL_SUCCESS;
  constructor(public quotation: any) { }
}

export class GetQuotationDetailError implements Action {
  readonly type = Types.GET_QUOTATION_DETAIL_ERROR;
  constructor(public error: string) { }
}

export class SubmitQuotationOrder implements Action {
  readonly type = Types.SUBMIT_QUOTATION_ORDER;
  constructor(public order: any) { }
}
export class SubmitQuotationOrderSuccess implements Action {
  readonly type = Types.SUBMIT_QUOTATION_ORDER_SUCCESS;
  constructor(public successOrderInfo: successOrderInfo) { }
}

export class SubmitQuotationOrderError implements Action {
  readonly type = Types.SUBMIT_QUOTATION_ORDER_ERROR;
  constructor(public error: string) { }
}
export class SetIsNewOrder implements Action {
  readonly type = Types.IS_NEW_ORDER;
  constructor(){}
}

export class ClearCheckoutData implements Action {
  readonly type = Types.CLEAR_DATA;
  constructor() { }
}

export class UpdateInventory implements Action {
  readonly type = Types.UPDATE_INVENTORY_QUOTATION ;
  constructor(public orderData: updateInventoryRequest) { }
}
export class UpdateInventorySuccess implements Action {
  readonly type = Types.UPDATE_INVENTORY_QUOTATION_SUCCESS;
  constructor() { }
}

export class UpdateInventoryError implements Action {
  readonly type = Types.UPDATE_INVENTORY_QUOTATION_ERROR;
  constructor(public error: string) { }
}

export type All
  = GetQuotations
  | GetQuotationsSuccess
  | GetQuotationsError
  | GetQuotationDetail
  | GetQuotationDetailSuccess
  | GetQuotationDetailError
  | SubmitQuotationOrder
  | SubmitQuotationOrderSuccess
  | SubmitQuotationOrderError
  | SetIsNewOrder
  | ClearCheckoutData
  | UpdateInventory
  | UpdateInventorySuccess
  | UpdateInventoryError
