import { Action } from '@ngrx/store';
import { CartRequest } from '@app/store/cart/cart.models';
import {Cart} from "@app/models/cart.model";

export enum Types {
  ADD_TO_CART = '[Cart] Add to cart: Start',
  ADD_TO_CART_SUCCESS = '[Cart] Add to cart: Success',
  ADD_TO_CART_ERROR = '[Cart] Add to cart: Error',

  REMOVE_FROM_CART = '[Cart] Remove from cart: Start',
  REMOVE_FROM_CART_SUCCESS = '[Cart] Remove from cart: Success',
  REMOVE_FROM_CART_ERROR = '[Cart] Remove from cart: Error',

  ADD_ITEM_QUANTITY = '[Cart] Add item quantity: Start',
  ADD_ITEM_QUANTITY_SUCCESS = '[Cart] Add item quantity: Success',
  ADD_ITEM_QUANTITY_ERROR = '[Cart] Add item quantity: Error',

  SUBTRACT_ITEM_QUANTITY = '[Cart] Subtract item quantity: Start',
  SUBTRACT_ITEM_QUANTITY_SUCCESS = '[Cart] Subtract item quantity: Success',
  SUBTRACT_ITEM_QUANTITY_ERROR = '[Cart] Subtract item quantity: Error',

  SET_CART_TO_ORDERED = '[Cart] Set cart to ordered: Start',
  SET_CART_TO_ORDERED_SUCCESS = '[Cart] Set cart to ordered: Success',
  SET_CART_TO_ORDERED_ERROR = '[Cart] Set cart to ordered: Error',

  CLEAR = '[Cart] Clear data: Start',
}

export class AddToCart implements Action {
  readonly type = Types.ADD_TO_CART;
  constructor(public payload: CartRequest) { }
}

export class AddToCartSuccess implements Action {
  readonly type = Types.ADD_TO_CART_SUCCESS;
  constructor(public cart: Cart) { }
}

export class AddToCartError implements Action {
  readonly type = Types.ADD_TO_CART_ERROR;
  constructor(public error: string) { }
}

export class RemoveFromCart implements Action {
  readonly type = Types.REMOVE_FROM_CART;
  constructor(public payload: CartRequest) { }
}

export class RemoveFromCartSuccess implements Action {
  readonly type = Types.REMOVE_FROM_CART_SUCCESS;
  constructor(public cart: Cart) { }
}

export class RemoveFromCartError implements Action {
  readonly type = Types.REMOVE_FROM_CART_ERROR;
  constructor(public error: string) { }
}

export class AddItemQuantity implements Action {
  readonly type = Types.ADD_ITEM_QUANTITY;
  constructor(public payload: CartRequest) { }
}

export class AddItemQuantitySuccess implements Action {
  readonly type = Types.ADD_ITEM_QUANTITY_SUCCESS;
  constructor(public cart: Cart) { }
}

export class AddItemQuantityError implements Action {
  readonly type = Types.ADD_ITEM_QUANTITY_ERROR;
  constructor(public error: string) { }
}

export class SubtractItemQuantity implements Action {
  readonly type = Types.SUBTRACT_ITEM_QUANTITY;
  constructor(public payload: CartRequest) { }
}

export class SubtractItemQuantitySuccess implements Action {
  readonly type = Types.SUBTRACT_ITEM_QUANTITY_SUCCESS;
  constructor(public cart: Cart) { }
}

export class SubtractItemQuantityError implements Action {
  readonly type = Types.SUBTRACT_ITEM_QUANTITY_ERROR;
  constructor(public error: string) { }
}

export class SetCartToOrdered implements Action {
  readonly type = Types.SET_CART_TO_ORDERED;
  constructor(public merchantId: string) { }
}

export class SetCartToOrderedSuccess implements Action {
  readonly type = Types.SET_CART_TO_ORDERED_SUCCESS;
  constructor() { }
}

export class SetCartToOrderedError implements Action {
  readonly type = Types.SET_CART_TO_ORDERED_ERROR;
  constructor(public error: string) { }
}

export class Clear implements Action {
  readonly type = Types.CLEAR;
  constructor() { }
}

export type All
    =
    | AddToCart
    | AddToCartSuccess
    | AddToCartError
    | RemoveFromCart
    | RemoveFromCartSuccess
    | RemoveFromCartError
    | SubtractItemQuantity
    | SubtractItemQuantitySuccess
    | SubtractItemQuantityError
    | Clear
    | AddItemQuantity
    | AddItemQuantitySuccess
    | AddItemQuantityError
    | SetCartToOrdered
    | SetCartToOrderedSuccess
    | SetCartToOrderedError
