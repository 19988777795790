import { Action } from "@ngrx/store";
import {BillRequest, MyBillsRequest} from "@app/store/bill/bill.models";

export enum Types {
  ADD_BILL = '[Bill] Add bill: Start',
  ADD_BILL_SUCCESS = '[Bill] Add bill: Success',
  ADD_BILL_ERROR = '[Bill] Add bill: Error',

  DELETE_BILL = '[Bill] Delete bill: Start',
  DELETE_BILL_SUCCESS = '[Bill] Delete bill: Success',
  DELETE_BILL_ERROR = '[Bill] Delete bill: Error',

  DELETE_BILL_FROM_STATE = '[Bill] Delete bill from state: Start',

  GET_BILLS = '[Bill] Get bills: Start',
  GET_BILLS_SUCCESS = '[Bill] Get bills: Success',
  GET_BILLS_ERROR = '[Bill] Get bills: Error',

  CLEAR_SELECTED_BILL = '[Bill] Clear selected bill state: Start',
  CLEAR = '[Bill] Clear bill state: Start',
}

export class AddBill implements Action {
    readonly type = Types.ADD_BILL;
    constructor(public bill: any) { }
}

export class AddBillSuccess implements Action {
    readonly type = Types.ADD_BILL_SUCCESS;
    constructor(public bill: any) { }
}

export class AddBillError implements Action {
    readonly type = Types.ADD_BILL_ERROR;
    constructor(public error: string) { }
}

export class DeleteBill implements Action {
    readonly type = Types.DELETE_BILL;
    constructor(public data: BillRequest) { }
}

export class DeleteBillSuccess implements Action {
    readonly type = Types.DELETE_BILL_SUCCESS;
    constructor(public billId: string) { }
}

export class DeleteBillError implements Action {
    readonly type = Types.DELETE_BILL_ERROR;
    constructor(public error: string) { }
}

export class DeleteBillFromState implements Action {
    readonly type = Types.DELETE_BILL_FROM_STATE;
    constructor(public billId: string) { }
}

export class GetBills implements Action {
    readonly type = Types.GET_BILLS;
    constructor(public data: MyBillsRequest) { }
}

export class GetBillsSuccess implements Action {
    readonly type = Types.GET_BILLS_SUCCESS;
    constructor(public bills: any[]) { }
}

export class GetBillsError implements Action {
    readonly type = Types.GET_BILLS_ERROR;
    constructor(public error: string) { }
}

export class ClearSelectedBill implements Action {
    readonly type = Types.CLEAR_SELECTED_BILL;
    constructor() { }
}

export class Clear implements Action {
  readonly type = Types.CLEAR;
  constructor() { }
}


export type All
  = AddBill
  | AddBillSuccess
  | AddBillError
  | DeleteBill
  | DeleteBillSuccess
  | DeleteBillError
  | DeleteBillFromState
  | GetBills
  | GetBillsSuccess
  | GetBillsError
  | ClearSelectedBill
  | Clear
