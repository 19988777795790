export class Constants {
  private static readonly ACCEPTED: string = 'accepted';
  private static readonly IN_CART: string = 'in cart';
  private static readonly IN_BILLING: string = 'in billing';
  public static readonly VERIFYING: string = 'verifying';
  private static readonly PREPARING: string = 'preparing';
  private static readonly ONGOING: string = 'ongoing';
  private static readonly COMPLETED: string = 'completed';
  private static readonly CANCELLED: string = 'cancelled';
  private static readonly FAILED: string = 'failed';
  private static readonly OVERDUE: string = 'overdue';
  private static readonly PAID: string = 'paid';
  private static readonly PENDING: string = 'pending';
  public static readonly DELETED: string = 'deleted';
  public static readonly PAYABLE: string = 'payable';
  public static readonly DRAFT: string = 'draft';
  public static readonly PAYMENT_SENT: string = 'payment sent';

  public static QUOTATION_DETAIL_STATE = {
    ACCEPT: 'To be accepted',
    ORDER: 'Order'
  }

  public static CRUD_MODE = {
    ADD: 'add',
    EDIT: 'edit',
    DELETE: 'delete'
  }

  public static ORDER_TYPE = {
    PRODUCT: 'product',
    QUOTATION: 'quotation'
  }

  public static BILLING_STATUS = {
    PAID: this.PAID,
    IN_BILLING: this.IN_BILLING,
    OVERDUE: this.OVERDUE,
    PENDING: this.PENDING,
    VERIFYING: this.VERIFYING,
  }

  public static BILLING_STATUS_LABEL = {
    'paid': this.PAID,
    'in billing': this.PAYABLE,
    'overdue': this.OVERDUE,
    'pending': this.PENDING,
    'verifying': this.PAYMENT_SENT,
    'cancelled': this.CANCELLED
  }

  public static SERVICE_ORDER_STATUS = {
    ACCEPTED: this.ACCEPTED,
    PENDING: this.PENDING,
    PAID: this.PAID,
    IN_BILLING: this.IN_BILLING,
    DRAFT: this.DRAFT,
    VERIFYING: this.VERIFYING,
    PREPARING: this.PREPARING,
    ONGOING: this.ONGOING,
    COMPLETED: this.COMPLETED,
    CANCELLED: this.CANCELLED,
    FAILED: this.FAILED
  }

  public static SERVICE_ORDER_STATUS_COLOR = {
    'verifying': 'light',
    'preparing': 'info',
    'ongoing': 'success',
    'completed': 'secondary',
    'cancelled': 'warning',
    'failed': 'danger',
    'draft': 'light'
  }

  public static PAYMENT_OPTION_TYPE = {
    FREE: 'Free',
    CASH: 'Cash',
    STRIPE: 'Stripe',
    SWIFT_PAY: 'SwiftPay',
    BANK_TRANSFER: 'Bank Transfer',
    QR_CODE: 'QR Code',
    PAYPAL: 'Paypal',
    SWISH: 'Swish'
  }

  public static PAYMENT_MESSAGES = {
    success: "Payment Successful!",
  }

  public static OPTION_GROUP_REQUIRED = {
    OPTIONAL: 'Optional',
    REQUIRED: 'Required'
  }

  public static PRODUCT_ORDER_STATUS = {
    IN_CART: this.IN_CART,
    DRAFT: this.DRAFT,
    VERIFYING: this.VERIFYING,
    PREPARING: this.PREPARING,
    ONGOING: this.ONGOING,
    COMPLETED: this.COMPLETED,
    CANCELLED: this.CANCELLED,
    FAILED: this.FAILED,
  }

  public static PRODUCT_ORDER_STATUS_COLOR = {
    'ongoing': 'info',
    'completed': 'success',
    'failed': 'danger',
    'cancelled': 'light',
    'verifying': 'light',
    'in cart': 'dark',
    'preparing': 'info',
    'draft': 'light',
  }

  public static PAYMENT_TERMS = {
    FULL_PAYMENT: 'Full Payment',
    INSTALLMENT: 'Installment',
    MONTHLY: 'Monthly Subscription',
    QUARTERLY: 'Quarterly Subscription',
    SEMI_ANNUAL: 'Semi-Annual Subscription',
    ANNUAL: 'Annual Subscription'
  }

  public static SUBSCRIPTION_NEXT_DAYS = {
    'Monthly Subscription': 30,
    'Quarterly Subscription': 90,
    'Semi-Annual Subscription': 180,
    'Annual Subscription': 365
  }

  public static BILLING_STATUS_COLOR = {
    'in billing': 'info',
    'paid': 'success',
    'verifying': 'warning',
    'pending': 'light',
    'overdue': 'danger'
  }

  public static PAYMENT_PAGE_TYPE = {
    CART: 'cart',
    BILL: 'bill'
  }

  public static INVOICE_STATUS = {
    DRAFT: this.DRAFT,
    VERIFYING: this.VERIFYING,
    PAID: this.PAID,
  }

  public static THUMBNAIL_PREFIXES: string[] = ['opt_large_', 'opt_medium_', 'opt_small_'];
  public static THUMBNAIL_SINGLE_PREF: string[] = ['opt_single_'];

  public static readonly PAGE_LIMIT: number = 12;

  public static POST_TYPE = {
    BASIC: 'basic',
    ADVANCE: 'advance'
  }

  public static POST_ELEMENT_TYPES = {
    H2: 'h2',
    H3: 'h3',
    PARAGRAPH: 'paragraph',
    IMAGE: 'img',
    CODE: 'code',
    YOUTUBE: 'youtube',
    LINK: 'link',
    LINK_BLANK: 'link_blank',
    BUTTON: 'button',
    BUTTON_BLANK: 'button_blank',
    SPACING: 'spacing'
  }
}
