import { Action } from '@ngrx/store';
import {GetProductsRequest, SearchProductsRequest} from './products-paging.models';
import { Product } from '@app/models/product.model';

export enum Types {

  GET_FIRST_PRODUCTS = '[Product] Get First Products: Start',
  GET_FIRST_PRODUCTS_SUCCESS = '[Product] Get First Products: Success',
  GET_FIRST_PRODUCTS_ERROR = '[Product] Get First Products: Error',

  GET_PREVIOUS_PRODUCTS = '[Product] Get Previous Products: Start',
  GET_PREVIOUS_PRODUCTS_SUCCESS = '[Product] Get Previous Products: Success',
  GET_PREVIOUS_PRODUCTS_ERROR = '[Product] Get Previous Products: Error',

  GET_NEXT_PRODUCTS = '[Product] Get Next Products: Start',
  GET_NEXT_PRODUCTS_SUCCESS = '[Product] Get Next Products: Success',
  GET_NEXT_PRODUCTS_ERROR = '[Product] Get Next Products: Error',

  SEARCH_PRODUCTS = '[Product] Search Products: Start',
  SEARCH_PRODUCTS_SUCCESS = '[Product] Search Products: Success',
  SEARCH_PRODUCTS_ERROR = '[Product] Search Products: Error',

}

export class GetFirstProducts implements Action {
  readonly type = Types.GET_FIRST_PRODUCTS;
  constructor(public request: GetProductsRequest) { }
}

export class GetFirstProductsSuccess implements Action {
  readonly type = Types.GET_FIRST_PRODUCTS_SUCCESS;
  constructor(public total: number, public firstProduct: any, public lastProduct: any, public products: Product[]) { }
}

export class GetFirstProductsError implements Action {
  readonly type = Types.GET_FIRST_PRODUCTS_ERROR;
  constructor(public error: string) { }
}

export class GetPreviousProducts implements Action {
  readonly type = Types.GET_PREVIOUS_PRODUCTS;
  constructor(public request: GetProductsRequest) { }
}

export class GetPreviousProductsSuccess implements Action {
  readonly type = Types.GET_PREVIOUS_PRODUCTS_SUCCESS;
  constructor(public total: number, public firstProduct: any, public lastProduct: any, public products: Product[]) { }
}

export class GetPreviousProductsError implements Action {
  readonly type = Types.GET_PREVIOUS_PRODUCTS_ERROR;
  constructor(public error: string) { }
}

export class GetNextProducts implements Action {
  readonly type = Types.GET_NEXT_PRODUCTS;
  constructor(public request: GetProductsRequest) { }
}

export class GetNextProductsSuccess implements Action {
  readonly type = Types.GET_NEXT_PRODUCTS_SUCCESS;
  constructor(public total: number, public firstProduct: any, public lastProduct: any, public products: Product[]) { }
}

export class GetNextProductsError implements Action {
  readonly type = Types.GET_NEXT_PRODUCTS_ERROR;
  constructor(public error: string) { }
}

export class SearchProducts implements Action {
  readonly type = Types.SEARCH_PRODUCTS;
  constructor(public request: SearchProductsRequest) { }
}

export class SearchProductsSuccess implements Action {
  readonly type = Types.SEARCH_PRODUCTS_SUCCESS;
  constructor(public total: number, public firstProduct: any, public lastProduct: any, public products: Product[]) { }
}

export class SearchProductsError implements Action {
  readonly type = Types.SEARCH_PRODUCTS_ERROR;
  constructor(public error: string) { }
}

export type All
    = GetFirstProducts
    | GetFirstProductsSuccess
    | GetFirstProductsError
    | GetPreviousProducts
    | GetPreviousProductsSuccess
    | GetPreviousProductsError
    | GetNextProducts
    | GetNextProductsSuccess
    | GetNextProductsError
    | SearchProducts
    | SearchProductsSuccess
    | SearchProductsError
