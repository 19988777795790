import { User } from '@app/models/user.model';
import * as fromActions from './user.actions';

export interface UserState {
    user: User;
    uid: string;
    loading: boolean;
    error: string;
    signinSuccess: boolean;
    isSignUpSuccess: boolean;
}

const initialState: UserState = {
    user: null,
    uid: null,
    loading: null,
    error: null,
    signinSuccess: false,
    isSignUpSuccess: false
};


export function reducer(state = initialState, action: fromActions.All): UserState {
    switch (action.type) {

        // Init

        case fromActions.Types.INIT: {
            return { ...state, loading: true };
        }

        case fromActions.Types.INIT_AUTHORIZED: {
            return { ...state, user: action.user, uid: action.uid, loading: false, error: null };
        }

        case fromActions.Types.INIT_UNAUTHORIZED: {
            return { ...state, user: null, loading: false, error: null };
        }

        case fromActions.Types.INIT_ERROR: {
            return { ...state, loading: false, error: action.error };
        }

        // Sign In

        case fromActions.Types.SIGN_IN_EMAIL: {
            return { ...state, loading: true, signinSuccess: false };
        }

        case fromActions.Types.SIGN_IN_EMAIL_SUCCESS: {
            return { ...state, user: action.user, uid: action.uid, loading: false, error: null, signinSuccess: true };
        }

        case fromActions.Types.SIGN_IN_EMAIL_ERROR: {
            return { ...state, error: action.error, loading: false, signinSuccess: false };
        }

        case fromActions.Types.SIGN_IN_GOOGLE: {
            return { ...state, loading: true };
        }

        case fromActions.Types.SIGN_IN_GOOGLE_SUCCESS: {
            return { ...state, user: action.user, uid: action.uid, loading: false, error: null };
        }

        case fromActions.Types.SIGN_IN_GOOGLE_ERROR: {
            return { ...state, error: action.error, loading: false };
        }

        case fromActions.Types.SIGN_IN_FACEBOOK: {
            return { ...state, loading: true };
        }

        case fromActions.Types.SIGN_IN_FACEBOOK_SUCCESS: {
            return { ...state, user: action.user, uid: action.uid, loading: false, error: null };
        }

        case fromActions.Types.SIGN_IN_FACEBOOK_ERROR: {
            return { ...state, error: action.error, loading: false };
        }


        // Sign Up

        case fromActions.Types.SIGN_UP_EMAIL: {
            return { ...state, loading: true, isSignUpSuccess: false, };
        }

        case fromActions.Types.SIGN_UP_EMAIL_SUCCESS: {
            return { ...state, loading: false, isSignUpSuccess: true, };
        }

        case fromActions.Types.SIGN_UP_EMAIL_ERROR: {
            return { ...state, error: action.error, loading: false, isSignUpSuccess: false, };
        }


        // Sign Out

        case fromActions.Types.SIGN_OUT: {
            return { ...state, loading: true };
        }

        case fromActions.Types.SIGN_OUT_SUCCESS: {
            return { ...initialState };
        }

        case fromActions.Types.SIGN_OUT_ERROR: {
            return { ...state, error: action.error, loading: false };
        }

        default: {
            return state;
        }
    }
}




