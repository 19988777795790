import { Component, OnInit, Input } from '@angular/core';

export interface Notification {
    message: string;
}

@Component({
  selector: 'app-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit {

  @Input() text: string;
  @Input() type: string;

  constructor() {
  }

  ngOnInit(): void {
  }

}
