import { Injectable } from '@angular/core';
import { NotificationComponent } from './components';
import { MdbNotificationService, MdbNotificationRef } from 'mdb-angular-ui-kit/notification';

@Injectable()
export class NotificationService {

    notificationRef: MdbNotificationRef<NotificationComponent> | null = null;

    constructor(private notificationService: MdbNotificationService) {}

    error(message: string): void {
        this.notificationRef = this.notificationService.open(NotificationComponent, { data: { text: message, type: 'warning-custom'} });
        setTimeout(() => {
            this.notificationRef.close();
          }, 4000);
    }

    success(message: string): void {
        this.notificationRef = this.notificationService.open(NotificationComponent, { data: { text: message, type: 'success'} });
        setTimeout(() => {
            this.notificationRef.close();
          }, 4000);
    }
}
