import * as fromActions from './cart.actions';
import { Cart } from "@app/models/cart.model";

export class CartState {
    cart: Cart;

    error: string;
    isAddedToCart: boolean;

    constructor(state?: any) {
        if(state && state.cart) {
            this.cart = Cart.createFromObject(state.cart);
        }
    }

    static deserialize(cartJson: any): CartState {
        return new CartState(cartJson);
    }
}

const initialState: CartState = {
    cart: null,
    error: null,
    isAddedToCart: false,
};

export function reducer(state: CartState = initialState, action: fromActions.All) {

  switch (action.type) {

      case fromActions.Types.ADD_TO_CART: {
          return { ...state,};
      }

      case fromActions.Types.ADD_TO_CART_SUCCESS: {
        return { ...state, cart: action.cart, isAddedToCart: true };
      }

      case fromActions.Types.ADD_TO_CART_ERROR: {
        return { ...state, error: action.error, isAddedToCart: false,};
      }

      case fromActions.Types.REMOVE_FROM_CART: {
        return { ...state,};
      }

      case fromActions.Types.REMOVE_FROM_CART_SUCCESS: {
        return { ...state, cart: action.cart,};
      }

      case fromActions.Types.REMOVE_FROM_CART_ERROR: {
        return { ...state, error: action.error,};
      }

      case fromActions.Types.ADD_ITEM_QUANTITY: {
        return { ...state,};
      }

      case fromActions.Types.ADD_ITEM_QUANTITY_SUCCESS: {
        return { ...state, cart: action.cart,};
      }

      case fromActions.Types.ADD_ITEM_QUANTITY_ERROR: {
        return { ...state, error: action.error,};
      }

      case fromActions.Types.SUBTRACT_ITEM_QUANTITY: {
        return { ...state,};
      }

      case fromActions.Types.SUBTRACT_ITEM_QUANTITY_SUCCESS: {
        return { ...state, cart: action.cart,};
      }

      case fromActions.Types.SUBTRACT_ITEM_QUANTITY_ERROR: {
        return { ...state, error: action.error,};
      }

    case fromActions.Types.SET_CART_TO_ORDERED: {
      return { ...state,};
    }

    case fromActions.Types.SET_CART_TO_ORDERED_SUCCESS: {
      return { ...state,};
    }

    case fromActions.Types.SET_CART_TO_ORDERED_ERROR: {
      return { ...state, error: action.error,};
    }

    case fromActions.Types.CLEAR: {
        return { ...state, cart: null};
    }

    default: {
        return state;
    }
  }
}
