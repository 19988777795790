import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QuotationState } from "./quotation.reducers";

export const getQuotationState = createFeatureSelector<QuotationState>('quotation');

export const getQuotations = createSelector(
  getQuotationState,
  (state) => state.quotations
)

export const getSelectedQuotation = createSelector(
  getQuotationState,
  (state) => state.selectedQuotation
)

export const getLoading = createSelector(
  getQuotationState,
  (state) => state.loading
);
export const getSuccessQuotationOrderInfo = createSelector(
  getQuotationState,
  (state) => state.successOrderInfo
);

export const getIsSuccess = createSelector(
getQuotationState,
(state) => state.isSuccess
);

export const getCheckoutDetails = createSelector(
getQuotationState,
(state) => state.checkoutDetails
);

export const getBillsCount = createSelector(
  getQuotationState,
  (state) => state.billsCount
)
