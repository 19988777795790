import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PostState } from "./posts.reducers";

export const selectPostState = createFeatureSelector<PostState>('post');

export const getPosts = createSelector(
  selectPostState,
  (state) => {
    if (Array.isArray(state.posts)) {
      return [...state.posts].sort((a, b) => {
        const convertToDate = (timestamp) => timestamp ? (timestamp.toDate ? timestamp.toDate() : new Date(timestamp)) : new Date(0);
        const aDate = convertToDate(a.updatedAt || a.createdAt);
        const bDate = convertToDate(b.updatedAt || b.createdAt);

        // Perform sorting in descending order
        return bDate.getTime() - aDate.getTime();
      });
    }
    return [];
  }
);

export const selectPost = createSelector(
    selectPostState,
    (state) => state.post
)

export const getElements = createSelector(
  selectPostState,
  (state) => state.elements
)
