
import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';

import {from, of, withLatestFrom} from 'rxjs';
import { map, switchMap, catchError, take } from 'rxjs/operators';

import * as fromActions from './products-paging.actions';

import * as fromProductPaging from '@app/store/product-paging';

import { NotificationService } from '@app/services';
import { Store } from "@ngrx/store";
import {ProductsRepository} from "@app/repository/products.repository";
@Injectable ()

export class ProductPagingEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    private productsRepository: ProductsRepository,
    public store: Store,
  ) { }

  public readFirstProducts = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_FIRST_PRODUCTS),
      map((action: fromActions.GetFirstProducts) => action.request),
      withLatestFrom(this.store.select(fromProductPaging.getFirstProduct), this.store.select(fromProductPaging.getPagingState)),
      switchMap(([request, firstProduct, pagingState]) =>
            from(this.productsRepository.getFirstProducts(request, firstProduct, pagingState.searchText)).pipe(
            map(response =>
                    new fromActions.GetFirstProductsSuccess(response.total, response.firstProduct, response.lastProduct, response.products)),
            catchError(err => {
              this.notification.error(err.message);
              return of(new fromActions.GetFirstProductsError(err.message))})
        )
      ))
  });

  public readPreviousProducts = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_PREVIOUS_PRODUCTS),
      map((action: fromActions.GetPreviousProducts) => action.request),
        withLatestFrom(this.store.select(fromProductPaging.getFirstProduct), this.store.select(fromProductPaging.getPagingState)),
        switchMap(([request, firstProduct, pagingState]) => {
            take(1)
            return from(this.productsRepository.getPreviousProducts(request, firstProduct, pagingState.searchText)).pipe(
            map((response) => new fromActions.GetPreviousProductsSuccess(response.total, response.firstProduct, response.lastProduct, response.products)),
            catchError(err => {
              this.notification.error(err.message);
              return of(new fromActions.GetPreviousProductsError(err.message))})
          );
        })
      )
  })

  public readNextProducts = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_NEXT_PRODUCTS),
      map((action: fromActions.GetNextProducts) => action.request),
        withLatestFrom(this.store.select(fromProductPaging.getLastProduct), this.store.select(fromProductPaging.getPagingState)),
        switchMap(([request, lastProduct, pagingState]) => {
            take(1)
            return from(this.productsRepository.getNextProducts(request, lastProduct, pagingState.searchText)).pipe(
            map((response) => new fromActions.GetNextProductsSuccess(response.total, response.firstProduct, response.lastProduct,response.products)),
            catchError(err => {
              this.notification.error(err.message);
              return of(new fromActions.GetNextProductsError(err.message))})
          );
        })
      )
  })

  public searchProducts = createEffect(() => {
      return this.actions.pipe(
          ofType(fromActions.Types.SEARCH_PRODUCTS),
          map((action: fromActions.SearchProducts) => action.request),
          switchMap((request) =>
              from(this.productsRepository.searchProducts(request)).pipe(
                  map(response =>
                      new fromActions.SearchProductsSuccess(response.total, response.firstProduct, response.lastProduct, response.products)),
                  catchError(err => {
                      this.notification.error(err.message);
                      return of(new fromActions.SearchProductsError(err.message))})
              )
          ))
  });

}
