import { createSelector, createFeatureSelector } from '@ngrx/store';
import { PaymentDetailsState } from './payment-details.reducer';

export const getPaymentDetailsState = createFeatureSelector<PaymentDetailsState>('paymentDetails')


export const getDetails = createSelector(
    getPaymentDetailsState,
    (state) => state.details
);
