import { Injectable } from '@angular/core';
import {DbPath} from "@app/shared/utils/db-path";
import {
    addDoc,
    collection,
    doc,
    docData,
    docSnapshots,
    Firestore,
    setDoc,
    updateDoc
} from "@angular/fire/firestore";
import {firstValueFrom, map, Observable} from "rxjs";
import {ProductOrder, QuotationOrder} from "@app/models/order.model";


@Injectable({
  providedIn: 'root'
})
export class OrdersRepository {

  constructor(private firestore: Firestore) { }

    getProductOrderById(merchantId: string, orderId: string):Observable<ProductOrder> {
        const ref = doc(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Orders}/${orderId}`);
        return docSnapshots(ref).pipe(
            map(order => {
                return ({id: order.id, ...order.data()}) as ProductOrder;
            })
        );
    }

    getQuotationOrderByIdAsync(merchantId: string, orderId: string):Observable<QuotationOrder> {
        const ref = doc(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Orders}/${orderId}`);
        return docSnapshots(ref).pipe(
            map(order => {
                return ({id: order.id, ...order.data()}) as QuotationOrder;
            })
        );
    }

  async getQuotationOrderById(merchantId: string, orderId: string): Promise<any> {
      const ref = doc(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Orders}/${orderId}`);
      let data = await firstValueFrom(docData(ref));
      return {id: orderId, ...data};
  }

    async createOrder(orderData: any): Promise<string> {
        let merchantId = orderData.merchantId;
        //create order
        const ordersCollection =
            collection(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Orders}`);
        let docReference = await addDoc(ordersCollection, this.getProductCheckoutData(orderData));

        let orderId = docReference.id;

        //put order id in order document
        await this.updateOrderId(merchantId, orderId);

        //create order items
        for (const orderItem of orderData.items) {
            await this.createOrderItem(orderItem, orderId, merchantId);
        }

        return orderId;
    }

    async updateOrder(data: any) {
        const ref =
            doc(this.firestore, `${DbPath.Merchants}/${data.merchantId}/${DbPath.Orders}/${data.orderId}`);
        await updateDoc(ref, data.orderData);
    }

    async updateOrderId(merchantId, orderId) {
        const ref = doc(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Orders}/${orderId}`);
        await setDoc(ref, {orderId: orderId}, {merge: true});
    }

    getProductCheckoutData(data){
        let products: any [] = [];
        data?.items.forEach(product => {
            products.push(product.name);
        })

        const orderData: any = {
            createdAt: new Date(),
            customerInfo: data.customerInfo,
            merchantId: data.merchantId,
            status: data.status,
            total: data.total,
            type: data.type,
            paymentDetails: data.paymentInfo,
            paymentInfo: data.paymentInfo,
            orderItemsLength: data.items.length,
            products: products,
            number: data.number,
        }
        return orderData;
    }

    async createOrderItem(orderItem: any, orderId: any, merchantId: string): Promise<string> {
        const ordersCollection =
            collection(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Orders}/${orderId}/${DbPath.OrderItems}`);
        let docReference =
            await addDoc(ordersCollection, {...orderItem, merchantId: merchantId, orderId: orderId, createdAt: new Date()});
        return docReference.id;
    }
}
