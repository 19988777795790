import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';
import { DbPath } from '../shared/utils/db-path';
import firebase from 'firebase/compat/app';
import { searchRequest } from '@app/store/product';
import {ApiService} from "@app/services/api.service";
@Injectable({
  providedIn: 'root'
})
export class ProductService {

  product: any
  constructor(
    private afs: AngularFirestore,
    private apiService: ApiService
  ) { }

  getProducts(merchantId: string): Observable<any[]>{
    return this.afs.collection<any>(DbPath.Merchants).doc(merchantId).collection(DbPath.Products, ref => ref
      .where('showOnPage','==', true)
      ).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({id: c.payload.doc.id, ...c.payload.doc.data()})
        ))
    )
  }

  searchProduct(searchData: searchRequest): Observable<any[]> {
    return this.afs.collection<any>(DbPath.Merchants).doc(searchData.merchantId).collection(DbPath.Products, ref => {
      return ref.where('trigram', 'array-contains-any', searchData.trigram.split(' '))
    }).snapshotChanges().pipe(
      map(products => {
        return products.map(product => ({id: product.payload.doc.id, ...product.payload.doc.data()}));
      })
    );
  }

  updateInventory(orderData){
    return this.afs.collection(DbPath.Merchants).doc(orderData.merchantId).collection(DbPath.Products).doc(orderData.productId).update({quantity: firebase.firestore.FieldValue.increment(-orderData.quantity)})
  }

  async getOptionGroups(data: any): Promise<any>{
    return await this.afs.collection<any>(DbPath.Merchants).doc(data.merchantId).collection(DbPath.Products).doc(data.productId).collection(DbPath.OptionGroups).get().toPromise()
    .then(res => {
        if (res.docs.length == 0) {
          return [];
        } else {
            let optionsGroup = []
            res.forEach(p => {
              optionsGroup.push({ ...p.data(), id: p.id, productId: p.ref.parent.parent.id,});
            });
            return optionsGroup;
        }
    }).catch(err => {
        console.log('An error occurred while getting product option groups ' + err);
    });
  }

  async getOptions(data: any): Promise<any>{
    return await this.afs.collection<any[]>(DbPath.Merchants).doc(data.merchantId).collection(DbPath.Products).doc(data.productId).collection(DbPath.OptionGroups).doc(data.optionGroupId).collection(DbPath.Options).get().toPromise()
    .then(res => {
        if (res.docs.length == 0) {
          return [];
        } else {
            let options = []
            res.forEach(p => {
                options.push({ ...p.data(), id: p.id, optionGroupId: p.ref.parent.parent.id, productId: p.ref.parent.parent.parent.parent.id});
            });
            return options;
        }
    }).catch(err => {
        console.log('An error occurred while getting product options ' + err);
    });
  }

  async updateProductQuantity(productId: string, newQuantity: number, merchantId: string) {
    try {
      const productRef = this.afs.collection<any>(DbPath.Merchants)
        .doc(merchantId)
        .collection(DbPath.Products)
        .doc(productId);
      await productRef.update({ quantity: newQuantity });
    } catch (error) {
    }
  }

  getProductFromApi(merchantRef: string, productRef: string): Observable<any>{
    return this.apiService.getData(`merchants/${merchantRef}/products/${productRef}`);
  }
}
