import { Post } from "@app/models/post.model";
import { createAction, props } from "@ngrx/store";
import { GetPost, ReadElements, SearchPost } from "./posts.models";
import { PostElement } from "@app/models/post-element.model";

export const listPosts = createAction(
    '[Posts] List Posts',
    props<{merchantId: string}>()
)

export const listPostsSuccess = createAction(
    '[Posts] List Posts Success',
    props<{posts: Post[]}>()
)

export const listPostsFailure = createAction(
    '[Posts] List Posts Failure',
    props<{error: any}>()
)

export const getPost = createAction(
    '[Posts] Get Post',
    props<{getPost: GetPost}>()
)

export const getPostSuccess = createAction(
    '[Posts] Get Post Success',
    props<{post: Post}>()
)

export const getPostFailure = createAction(
    '[Posts] Get Post Failure',
    props<{error: any}>()
)

export const searchPost = createAction(
    '[Posts] Search Post',
    props<{searchPost: SearchPost}>()
)

export const searchPostSuccess = createAction(
    '[Posts] Search Post Success',
    props<{posts: Post []}>()
)

export const searchPostFailure = createAction(
    '[Posts] Search Post Failure',
    props<{error: any}>()
)

export const readElements = createAction(
    '[Post] Read Elements',
    props<{ readElements: ReadElements}>()
)

export const readElementsSuccess = createAction(
    '[Post] Read Elements Success',
    props<{ elements: PostElement[] }>()
)

export const readElementsFailure = createAction(
    '[Post] Read Elements Failure',
    props<{ error: any }>()
)
