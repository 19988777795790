import {FormGroup} from "@angular/forms";

export const markFormGroupTouched = (formGroup) => {
    (Object as any).values(formGroup.controls).forEach(control => {
        control.markAsTouched();

        if (control.controls) {
            markFormGroupTouched(control);
        }
    });
};
export const areAllInputsFilled = (formGroup: FormGroup): boolean => {
  for (const key in formGroup.controls) {
    if (formGroup.controls.hasOwnProperty(key)) {
      const control = formGroup.controls[key];
      if (control.value == null || control.value === '') {
        return false;
      }
    }
  }
  return true;
}

