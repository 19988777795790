import { Component} from '@angular/core';

import {Unsub} from "@app/shared";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent extends Unsub {

  constructor(
  ) {
    super();
  }


}
