import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {DbPath} from '../shared/utils/db-path';
import {AngularFirestore} from '@angular/fire/compat/firestore';
import firebase from 'firebase/compat/app';
import {filter, switchMap} from "rxjs/operators";
import { searchRequest } from '@app/store/quotation';
import {ApiService} from "@app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class QuotationService {
  quotation: any;
  constructor(private afs: AngularFirestore,
              private apiService: ApiService) { }



  getQuotations(merchantId: string): Observable<any[]> {
    return this.afs.collection<any>(DbPath.Merchants)
      .doc(merchantId)
      .collection(DbPath.Quotations, ref => ref.orderBy('createdAt', 'desc'))
      .snapshotChanges()
      .pipe(
        map(changes =>
          changes.map(c => ({id: c.payload.doc.id, ...c.payload.doc.data()}))
      )
    )
  }

  getQuotationDetail(request:any): Observable<any> {
    const quoteByPermalink = this.afs.collection<any>(DbPath.Merchants)
      .doc(request.merchantId)
      .collection(DbPath.Quotations,
        ref => ref.where('permalink', '==', request.quotationId)).snapshotChanges().pipe(
        map(changes =>
          changes.length > 0 ?
            changes[0]
            : null
        ));
    return quoteByPermalink.pipe(
      map( c => {
        return c != null
      }),
      switchMap(hasQuotation => {
        if(hasQuotation) {
          return quoteByPermalink.pipe(
            filter(result => !!result),
            map(c =>
              ({ id: c.payload.doc.id, ...c.payload.doc.data(),
              })
            ));
        }
        return this.afs.collection<any>(DbPath.Merchants)
          .doc(request.merchantId)
          .collection(DbPath.Quotations)
          .doc(request.quotationId)
          .snapshotChanges()
          .pipe(
            map(quotation => {
              return ({id: quotation.payload.id, ...quotation.payload.data()});
            })
          );
      }));
  }

  searchQuotation(searchData: searchRequest): Observable<any[]> {
    return this.afs.collection<any>(DbPath.Merchants).doc(searchData.merchantId).collection(DbPath.Quotations, ref => {
      return ref.where('trigram', 'array-contains-any', searchData.trigram.split(' '))
    }).snapshotChanges().pipe(
      map(quotations => {
        return quotations.map(quotation => ({id: quotation.payload.doc.id, ...quotation.payload.doc.data()}));
      })
    );
  }

  updateInventory(orderData: any) {
    return this.afs.collection(DbPath.Merchants).doc(orderData.merchantId).collection(DbPath.Products).doc(orderData.quotationId).update({quantity: firebase.firestore.FieldValue.increment(-orderData.quantity)})
  }

  getQuotationFromApi(merchantRef: string, quotationRef: string): Observable<any>{
    console.log("quotation from api: ", `merchants/${merchantRef}/quotations/${quotationRef}`);
    return this.apiService.getData(`merchants/${merchantRef}/quotations/${quotationRef}`);
  }

  public calculateSubtotal(totalAmount: number, vatRate: number): number  {
    const vatFactor = 1 + (vatRate / 100);
    return totalAmount / vatFactor;
  }

  public calculateVAT(totalAmount: number, vatRate: number): number {
    const vatFactor = 1 + (vatRate / 100);
    const amountBeforeVAT = totalAmount / vatFactor;
    return totalAmount - amountBeforeVAT;
  }
}
