import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { Observable, map } from 'rxjs';
import { DbPath } from '../shared/utils/db-path';
import {filter, switchMap} from "rxjs/operators";
import {ApiService} from "@app/services/api.service";

@Injectable({
  providedIn: 'root'
})
export class MerchantService {

  constructor(
    private afs: AngularFirestore,
    private apiService: ApiService
  ) { }

  getMerchant(merchantRef: string): Observable<any[]>{
    var merchantByCode = this.afs.collection<any>(DbPath.Merchants,
      ref => ref.where('code', '==', merchantRef.toLowerCase()).limit(1)).snapshotChanges().pipe(
      map(changes =>
        changes.length > 0 ?
          changes[0]
          : null
      ));
    return merchantByCode.pipe(
      map( c => {
        return c != null
      }),
      switchMap(isMerchantNotNull => {
        if(isMerchantNotNull) {
          return merchantByCode.pipe(
            filter(result => !!result),
            map(c =>
              ({ id: c.payload.doc.id, ...c.payload.doc.data(),
              })
            ));
        }
        return this.afs.doc<any>(`${DbPath.Merchants}/${merchantRef}`).snapshotChanges().pipe(
          filter(result => !!result),
          map(c =>
            ({ id: c.payload.id, ...c.payload.data() })
          ));
      }));
  }

  getMerchantFromApi(merchantRef: string): Observable<any>{
    return this.apiService.getData(`merchants/${merchantRef}`);
  }
}
