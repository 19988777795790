import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';

import { from, of } from 'rxjs';
import { map, switchMap, catchError, take, tap } from 'rxjs/operators';

import { environment } from 'environments/environment';


import { User } from './user.models';
import firebase from 'firebase/compat/app';

import * as fromActions from './user.actions';

import { NotificationService } from '@app/services';
import { DbPath } from '@app/shared/utils/db-path';
import GoogleAuthProvider = firebase.auth.GoogleAuthProvider;
import FacebookAuthProvider = firebase.auth.FacebookAuthProvider;

@Injectable ()
export class UserEffects {
    constructor (
        private actions: Actions,
        private afAuth: AngularFireAuth,
        private afs: AngularFirestore,
        private notification: NotificationService
    ) { }

    public init = createEffect(() => {
      return this.actions.pipe(
          ofType(fromActions.Types.INIT),
          switchMap(() => this.afAuth.authState.pipe(take(1))),
          switchMap(authState => {
              if (authState) {
                  return this.afs.doc<User>(`users/${authState.uid}`).valueChanges().pipe(
                      take(1),
                      map((user) => {
                          if(user){
                              user.id = user.id? user.id : authState.uid
                              return new fromActions.InitAuthorized(authState.uid, user)
                          } else {
                              return new fromActions.InitError('User not found')
                          }
                        }),
                      catchError(err => of(new fromActions.InitError(err.message)))
                  );
              } else {
                  return of(new fromActions.InitUnauthorized());
              }
          })
      );
    })

    signInEmail = createEffect(() =>  this.actions.pipe(
      ofType(fromActions.Types.SIGN_IN_EMAIL),
      map((action: fromActions.SignInEmail) => action.credentials),
      switchMap(credentials =>
        from(this.afAuth.signInWithEmailAndPassword(credentials.email, credentials.password)).pipe(
            switchMap(signInState => {
                if(signInState.user.emailVerified){
                    return this.afs.doc<User>(`users/${signInState.user.uid}`).valueChanges().pipe(
                        take(1),
                        tap(async (user) => {
                            if(!user){
                                await this.afs.collection(DbPath.Users).doc(signInState.user.uid).set(this.initUserdata(signInState.user))
                            }
                        }),
                        map((user) => {
                            let _userData  = user? user : this.initUserdata(signInState.user)
                            _userData.id = _userData.id? _userData.id: signInState.user.uid
                            return  new fromActions.SignInEmailSuccess(signInState.user.uid, _userData)
                        })
                    );
                } else {
                    this.sendEmailVerification()
                    this.notification.error('Email not verified');
                    return of(new fromActions.SignInEmailNotVerify());
                }
              }
            ),
            catchError(err => {
                this.notification.error(err.message);
                return of(new fromActions.SignInEmailError(err.message));
            })
        )
      )
    ));

    signInGoogle  = createEffect(() => this.actions.pipe(
        ofType(fromActions.Types.SIGN_IN_GOOGLE),
        map((action: fromActions.SignInGoogle) => action.payload),
        switchMap(() => from(this.afAuth.signInWithPopup(new GoogleAuthProvider)).pipe(
                switchMap(signInState =>
                    this.afs.doc<User>(`users/${signInState.user.uid}`).valueChanges().pipe(
                        take(1),
                        tap(async (user) => {
                            if(!user){
                                await this.afs.collection(DbPath.Users).doc(signInState.user.uid).set(this.initUserdata(signInState.user))

                            }
                        }),
                        map((user) => {
                            let _userData  = user? user : this.initUserdata(signInState.user)
                            return  new fromActions.SignInGoogleSuccess(signInState.user.uid, _userData || null)
                        })
                    )
                ),
                catchError(err => {
                    this.notification.error(err.message);
                    return of(new fromActions.SignInGoogleError(err.message));
                })

            )
        )
    ));

    signInFacebook = createEffect(() => {
        return this.actions.pipe(
            ofType(fromActions.Types.SIGN_IN_FACEBOOK),
            map((action: fromActions.SignInFacebook) => action.payload),
            switchMap(() => from(this.afAuth.signInWithPopup(new FacebookAuthProvider())).pipe(
                    switchMap(signInState =>
                        this.afs.doc<User>(`${DbPath.Users}/${signInState.user.uid}`).valueChanges().pipe(
                            take(1),
                            tap(async (user) => {
                                if(!user){
                                    await this.afs.collection(DbPath.Users).doc(signInState.user.uid).set(this.initUserdata(signInState.user))
                                }
                            }),
                            map((user) => {
                                let _userData  = user? user : this.initUserdata(signInState.user)
                                return  new fromActions.SignInFacebookSuccess(signInState.user.uid, _userData || null)
                            })
                        )
                    ),
                    catchError(err => {
                        this.notification.error(err.message);
                        return of(new fromActions.SignInFacebookError(err.message));
                    })

                )
            )
        );

    })


    signUpEmail = createEffect(() => this.actions.pipe(
      ofType(fromActions.Types.SIGN_UP_EMAIL),
      map((action: fromActions.SignUpEmail) => action.credentials),
      switchMap(credentials =>
          from(this.afAuth.createUserWithEmailAndPassword(credentials.email, credentials.password)).pipe(
              tap(async () => {
                  this.sendEmailVerification()
                  this.notification.success('Please, confirm your email. verification link has been sent to your email address');
              }),
              map(() => new fromActions.SignUpEmailSuccess()),
              catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.SignUpEmailError(err.message));
              })
          )
      )
    ));

    signOut = createEffect(() =>  this.actions.pipe(
      ofType(fromActions.Types.SIGN_OUT),
      switchMap(() =>
          from(this.afAuth.signOut()).pipe(
              map(() => new fromActions.SignOutSuccess()),
              // tap(() => this.router.navigateByUrl('auth/login')),
              catchError(err => of(new fromActions.SignOutError(err.message)))
          )
      )
  ));

// ******************************************
// Internal Methods
// ******************************************

    initUserdata(user: any): User {
      return {
              id: user.uid,
              fullName: user.displayName? user.displayName: user.email.split('@')[0],
              email: user.email,
              image: user.photoURL,
              phone:  user.phoneNumber,
              role: 'Customer',
              createdAt: new Date(),
              updatedAt: new Date(),
          }
    }

    async sendEmailVerification(){
      const merchantCode = location.pathname.split('/')[1];
      await (await this.afAuth.currentUser).sendEmailVerification(
        {
          url: environment.firebase.verificationRedirectUrl + merchantCode,
          handleCodeInApp: true
        }
      );
    }
}
