import * as fromActions from '@app/store/bill';
import {Constants} from "@app/shared/utils/constant";
export interface BillState {
  bills: any[];
  selectedBill: any;
  loading: boolean;
  error: string;
  isSuccess: boolean;
  deleteSuccess: boolean;
}

const initialState: BillState = {
  bills: [],
  selectedBill: null,
  loading: false,
  error: null,
  isSuccess: false,
  deleteSuccess: false
}

export function reducer(state: BillState = initialState, action: fromActions.All){
  switch(action.type) {
    case fromActions.Types.ADD_BILL: {
      return { ...state, loading: true, error: null, isSuccess: false };
    }
    case fromActions.Types.ADD_BILL_SUCCESS: {
        return { ...state, loading: false, bills: [...state.bills, action.bill], selectedBill: action.bill, error: null, isSuccess: true};
    }
    case fromActions.Types.ADD_BILL_ERROR: {
        return { ...state, loading: false, error: action.error, isSuccess: false};
    }
    case fromActions.Types.DELETE_BILL: {
      return {...state, loading: true, error: null, deleteSuccess: false}
    }
    case fromActions.Types.DELETE_BILL_SUCCESS: {
      let indexItem = state.bills.findIndex((_item: any) => _item?.billId == action.billId)
      let newBills = [...state.bills];
      newBills.splice(indexItem, 1)
      return {...state, bills: newBills, loading: false, error: null, deleteSuccess: true}
    }
    case fromActions.Types.DELETE_BILL_ERROR: {
      return {...state, loading: false, error: action.error, deleteSuccess: false}
    }
    case fromActions.Types.DELETE_BILL_FROM_STATE: {
      let indexItem = state.bills.findIndex((_item: any) => _item?.billId == action.billId)
      let newBills = [...state.bills];
      newBills.splice(indexItem, 1)
      return {...state, bills: newBills}
    }
    case fromActions.Types.GET_BILLS: {
      return { ...state, loading: true, error: null, isSuccess: false };
    }
    case fromActions.Types.GET_BILLS_SUCCESS: {
      let billIds = new Set(state.bills.map(bill => bill.id ?? bill.billId));
      let filteredBills = [...state.bills, ...action.bills.filter(bill => !billIds.has(bill.id ?? bill.billId))];
      filteredBills = filteredBills
          .filter((bill) =>
              bill.schedule ? bill.schedule.find((schedule) => schedule.status === Constants.BILLING_STATUS.IN_BILLING) : false);
      return { ...state, loading: false, bills: filteredBills, error: null, isSuccess: true};
    }
    case fromActions.Types.GET_BILLS_ERROR: {
      return { ...state, loading: false, error: action.error, isSuccess: false};
    }
    case fromActions.Types.CLEAR_SELECTED_BILL: {
      return { ...state, selectedBill: null };
    }
    case fromActions.Types.CLEAR: {
      return { ...initialState };
    }

    default: {
      return state;
    }
  }
}
