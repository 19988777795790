import { createFeatureSelector, createSelector } from "@ngrx/store";
import { PostPagingState } from "./posts-paging.reducers";

export const getPostState = createFeatureSelector<PostPagingState>('postPaging');


export const getPostByPage = createSelector(
    getPostState,
  (state) => state.postsByPage
)

export const getError = createSelector(
    getPostState,
  (state) => state.error
)

export const getLoading = createSelector(
  getPostState,
  (state) => state.loading
)

export const getFirstPost = createSelector(
    getPostState,
    (state) => state.firstPost
)

export const getLastPost = createSelector(
    getPostState,
    (state) => state.lastPost
)

export const getPagingState = createSelector(
    getPostState,
    (state) =>
        ({
            total: state.total,
            page: state.page,
            searchText: state.searchText
        })
)
