import { createSelector, createFeatureSelector } from '@ngrx/store';
import { CartState } from './cart.reducers';

export const getCartState = createFeatureSelector<CartState>('cart');

export const getCart = createSelector(
    getCartState,
    (state: CartState) => state.cart
)

export const getIsAddedToCart = createSelector(
  getCartState,
  (state) => state.isAddedToCart
)
