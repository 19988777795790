import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import {map, switchMap, catchError, mergeMap, take} from 'rxjs/operators';

import { NotificationService } from '@app/services';
import { Store } from "@ngrx/store";
import * as fromActions from './bill.actions';
import { OrdersService } from "@app/services/orders.service";
import * as fromQuotation from 'app/store/quotation';
import {BillsService} from "@app/services/bills.service";

@Injectable ()

export class BillEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    public store: Store,
    private ordersService: OrdersService,
    private billsService: BillsService,
  ) { }



  public addBill$ = createEffect(() =>
      this.actions.pipe(
       ofType(fromActions.Types.ADD_BILL),
       map((action: fromActions.AddBill) => action.bill),
       switchMap((bill) =>
        from(this.ordersService.createQuotationOrder(bill)).pipe(
            map((billId) => {
                this.ordersService.setQuotationOrderItems(bill, billId)
                bill.items.forEach((billItem) => {
                  this.store.dispatch(new fromQuotation.UpdateInventory({merchantId: bill.merchantId, quotationId: billItem.id, quantity: billItem.orderQuantity}))
                })
                return new fromActions.AddBillSuccess({...bill, billId})
            }),
            catchError(err => {
                return of(new fromActions.AddBillError(err.message))})
            )),
          catchError(err => {
              return of(new fromActions.AddBillError(err.message))
          })
          )
        )

    public deleteBill = createEffect(() => {
        return this.actions.pipe(
            ofType(fromActions.Types.DELETE_BILL),
            map((action: fromActions.DeleteBill) => action.data),
            switchMap(data =>
                from(this.ordersService.deleteOrder({merchantId: data.merchantId, orderId: data.billId})).pipe(
                    map(() => new fromActions.DeleteBillSuccess(data.billId)),
                    catchError(err => {
                        this.notification.error(err.message)
                        return of(new fromActions.DeleteBillError(err.message))
                    })
                )
            )
        )
    })

    public getBills = createEffect(() => {
        return this.actions.pipe(
            ofType(fromActions.Types.GET_BILLS),
            map((action: fromActions.GetBills) => action.data),
            mergeMap((payload) => {
                take(1)
                return this.billsService.getMyBills(payload.merchantId, payload.customerId).pipe(
                    map((bills) => new fromActions.GetBillsSuccess(bills)),
                    catchError(err => {
                        this.notification.error(err.message);
                        return of(new fromActions.GetBillsError(err.message))})
                );
            })
        )
    })
}
