import { Quotation } from '@app/models/quotation.model';
import * as fromActions from '@app/store/quotation-paging';
export interface QuotationPagingState {
  quotationsByPage: Quotation[];
  total: number;
  page: number;
  searchText: string;
  firstQuotation: any;
  lastQuotation: any;
  loading: boolean;
  error: string;
}

const initialState: QuotationPagingState = {
  quotationsByPage: [],
  total: 0,
  page: 0,
  searchText: '',
  firstQuotation: null,
  lastQuotation: null,
  loading: false,
  error: null,
}

export function reducer(state: QuotationPagingState = initialState, action: fromActions.All){
  switch(action.type) {

    case fromActions.Types.GET_FIRST_QUOTATIONS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_FIRST_QUOTATIONS_SUCCESS: {
        return { ...state, loading: false,
          total: action.total,
          firstQuotation: action.firstQuotation,
          lastQuotation: action.lastQuotation,
          quotationsByPage: action.quotations, error: null };
    }
    case fromActions.Types.GET_FIRST_QUOTATIONS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_PREVIOUS_QUOTATIONS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_PREVIOUS_QUOTATIONS_SUCCESS: {
        return { ...state, loading: false,
          total: action.total,
          page: state.page - 1,
          firstQuotation: action.firstQuotation,
          lastQuotation: action.lastQuotation,
          quotationsByPage: action.quotations, error: null };
    }
    case fromActions.Types.GET_PREVIOUS_QUOTATIONS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_NEXT_QUOTATIONS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_NEXT_QUOTATIONS_SUCCESS: {
        return { ...state, loading: false,
          total: action.total,
          page: state.page + 1,
          firstQuotation: action.firstQuotation,
          lastQuotation: action.lastQuotation,
          quotationsByPage: action.quotations, error: null };
    }
    case fromActions.Types.GET_NEXT_QUOTATIONS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.SEARCH_QUOTATIONS: {
      return { ...state, searchText: action.request.searchText, loading: true, error: null };
    }
    case fromActions.Types.SEARCH_QUOTATIONS_SUCCESS: {
      return { ...state, loading: false,
        total: action.total,
        page: 0,
        firstQuotation: action.firstQuotation,
        lastQuotation: action.lastQuotation,
        quotationsByPage: action.quotations, error: null };
    }
    case fromActions.Types.SEARCH_QUOTATIONS_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    default: {
      return state;
    }
  }
}
