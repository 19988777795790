import { Injectable } from '@angular/core';
import { Actions, ofType, createEffect } from '@ngrx/effects';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { from, of } from 'rxjs';
import {map, switchMap, catchError, mergeMap} from 'rxjs/operators';


import * as fromActions from './payment-details.actions';
import { DbPath } from '@app/shared/utils/db-path';
import { NotificationService } from '@app/services/notification/notification.service';
import firebase from "firebase/compat";
import DocumentReference = firebase.firestore.DocumentReference;

@Injectable()
export class PaymentDetailsEffects {
    constructor(
        private actions: Actions,
        private afs: AngularFirestore,
        private notification: NotificationService,
    ) {}


    updatePaymentStatus$ = createEffect(() =>
        this.actions.pipe(
            ofType(fromActions.Types.UPDATE_PAYMENT_STATUS),
            map((action: fromActions.UpdatePaymentStatus) => action.details),
            switchMap(details =>
                from(this.updateOrderPaymentStatus(details)).pipe(
                    map(() => new fromActions.UpdatePaymentStatusSuccess()),
                    catchError(err => {
                        this.notification.error(err.message);
                        return of(new fromActions.UpdatePaymentStatusError(err.message))})
                )
            )
        )
    );

// ******************************************
    // Internal Methods
    // ******************************************

    async updateOrderPaymentStatus(details: any) {
        const orderSnapshot = await this.afs.collection(DbPath.Orders, ref => ref
            .where("paymentDetails.paymentCode", "==", details.paymentCode)
            .where("paymentDetails.paymentIntentId", "==", details.paymentIntentId)
            .limit(1))
            .snapshotChanges()
            .pipe(mergeMap(orders => orders));
        let orderRef: DocumentReference;
        let orderSubscription = orderSnapshot.subscribe(doc => {
            orderRef = doc.payload.doc.ref;
            orderRef.update({status: details.status, updatedAt: new Date()});
            orderSubscription.unsubscribe();
        });
    }

}
