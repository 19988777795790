import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, switchMap, catchError, take } from 'rxjs/operators';

import { NotificationService } from '@app/services';
import { QuotationService } from "@app/services/quotation.service";
import { Store } from "@ngrx/store";
import * as fromActions from './quotation.actions';
import { OrdersService } from "@app/services/orders.service";
import * as fromQuotation from 'app/store/quotation';

@Injectable ()

export class QuotationEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    public quotationService: QuotationService,
    public store: Store,
    private ordersService: OrdersService,
  ) { }

  public read = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_QUOTATIONS),
      map((action: fromActions.GetQuotations) => action.merchantId),
      switchMap((payload) => {
        take(1);
        return this.quotationService.getQuotations(payload).pipe(
          map((quotations) => new fromActions.GetQuotationsSuccess(quotations)),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.GetQuotationsError(err.message))
          })
        )
      })
    )
  })

  public readDetail = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_QUOTATION_DETAIL),
      map((action: fromActions.GetQuotationDetail) => action.request),
      switchMap(request => {
        take(1);
        return this.quotationService.getQuotationDetail(request).pipe(
          map(quotation => new fromActions.GetQuotationDetailSuccess(quotation)),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.GetQuotationsError(err.message))
          })
        );
      })
    );
  });

  public updateInventory = createEffect(() =>
  this.actions.pipe(
    ofType(fromActions.Types.UPDATE_INVENTORY_QUOTATION),
    map((action: fromActions.UpdateInventory) => action.orderData),
    switchMap(orderData =>
      from(this.quotationService.updateInventory(orderData)).pipe(
        map(() => {
            return new fromActions.UpdateInventorySuccess()}),
        catchError(err => {
            return of(new fromActions.UpdateInventoryError(err.message))
        })
      )
    )
  ));

  public submitOrder$ = createEffect(() =>
  this.actions.pipe(
   ofType(fromActions.Types.SUBMIT_QUOTATION_ORDER),
   map((action: fromActions.SubmitQuotationOrder) => action.order),
   switchMap((order) =>
    from(this.ordersService.createQuotationOrder(order.order)).pipe(
        map((orderId) => {
            this.ordersService.setQuotationOrderItems(order.order, orderId)
            order.order.items.forEach((orderItems) => {
              this.store.dispatch(new fromQuotation.UpdateInventory({merchantId: order.order.merchantId, quotationId: orderItems.id, quantity: orderItems.orderQuantity}))
            })
            return new fromActions.SubmitQuotationOrderSuccess({order: order.order, orderId: orderId, isSuccess: true})
        }),
        catchError(err => {
            return of(new fromActions.SubmitQuotationOrderError(err.message))})
        )),
      catchError(err => {
          return of(new fromActions.SubmitQuotationOrderError(err.message))
      })
      )
    )
}
