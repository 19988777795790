import { Action } from '@ngrx/store';
import {  EmailPasswordCredentials } from './user.models';
import { User } from '@app/models/user.model';

export enum Types {

    INIT = '[User] Init: Start',
    INIT_AUTHORIZED = '[User] Init: Authorized',
    INIT_UNAUTHORIZED = '[User] Init: Unauthorized',
    INIT_ERROR = '[User] Init: Error',

    SIGN_IN_EMAIL = '[User] Sign In with email: Start',
    SIGN_IN_EMAIL_SUCCESS = '[User] Sign In with email: Success',
    EMAIL_NOT_VERIFY = '[User] Email not verified: Error',
    SIGN_IN_EMAIL_ERROR = '[User] Sign In with email: Error',

    SIGN_IN_GOOGLE = '[User] Sign In with google: Start',
    SIGN_IN_GOOGLE_SUCCESS = '[User] Sign In with google: Success',
    SIGN_IN_GOOGLE_ERROR = '[User] Sign In with google: Error',

    SIGN_IN_FACEBOOK = '[User] Sign In with facebook: Start',
    SIGN_IN_FACEBOOK_SUCCESS = '[User] Sign In with facebook: Success',
    SIGN_IN_FACEBOOK_ERROR = '[User] Sign In with facebook: Error',

    SIGN_UP_EMAIL = '[User] Sign Up with email: Start',
    SIGN_UP_EMAIL_SUCCESS = '[User] Sign Up with email: Success',
    SIGN_UP_EMAIL_ERROR = '[User] Sign Up with email: Error',

    SIGN_OUT = '[User] Sign Out: Start',
    SIGN_OUT_SUCCESS = '[User] Sign Out: Success',
    SIGN_OUT_ERROR = '[User] Sign Out: Error',
}

// Init

export class Init implements Action {
    readonly type = Types.INIT;
    constructor() { }
}

export class InitAuthorized implements Action {
    readonly type = Types.INIT_AUTHORIZED;
    constructor(public uid: string, public user: User) { }
}

export class InitUnauthorized implements Action {
    readonly type = Types.INIT_UNAUTHORIZED;
    constructor() { }
}

export class InitError implements Action {
    readonly type = Types.INIT_ERROR;
    constructor(public error: string) { }
}

//Sign In

export class SignInEmail implements Action {
    readonly type = Types.SIGN_IN_EMAIL;
    constructor(public credentials: EmailPasswordCredentials) { }
}

export class SignInEmailSuccess implements Action {
    readonly type = Types.SIGN_IN_EMAIL_SUCCESS;
    constructor(public uid: string, public user: User) { }
}

export class SignInEmailError implements Action {
    readonly type = Types.SIGN_IN_EMAIL_ERROR;
    constructor(public error: string) { }
}

export class SignInEmailNotVerify implements Action {
    readonly type = Types.EMAIL_NOT_VERIFY;
    constructor() { }
}

export class SignInGoogle implements Action {
    readonly type = Types.SIGN_IN_GOOGLE;
    constructor(public payload?: any) { }
}

export class SignInGoogleSuccess implements Action {
    readonly type = Types.SIGN_IN_GOOGLE_SUCCESS;
    constructor(public uid: string, public user: User) { }
}

export class SignInGoogleError implements Action {
    readonly type = Types.SIGN_IN_GOOGLE_ERROR;
    constructor(public error: string) { }
}

export class SignInFacebook implements Action {
    readonly type = Types.SIGN_IN_FACEBOOK;
    constructor(public payload?: any) { }
}

export class SignInFacebookSuccess implements Action {
    readonly type = Types.SIGN_IN_FACEBOOK_SUCCESS;
    constructor(public uid: string, public user: User) { }
}

export class SignInFacebookError implements Action {
    readonly type = Types.SIGN_IN_FACEBOOK_ERROR;
    constructor(public error: string) { }
}

// Sign Up

export class SignUpEmail implements Action {
    readonly type = Types.SIGN_UP_EMAIL;
    constructor(public credentials: EmailPasswordCredentials) { }
}

export class SignUpEmailSuccess implements Action {
    readonly type = Types.SIGN_UP_EMAIL_SUCCESS;
    constructor() { }
}

export class SignUpEmailError implements Action {
    readonly type = Types.SIGN_UP_EMAIL_ERROR;
    constructor(public error: string) { }
}

 // Sign Out

export class SignOut implements Action {
    readonly type = Types.SIGN_OUT;
    constructor() { }
}

export class SignOutSuccess implements Action {
    readonly type = Types.SIGN_OUT_SUCCESS;
    constructor() { }
}

export class SignOutError implements Action {
    readonly type = Types.SIGN_OUT_ERROR;
    constructor(public error: string) { }
}


 export type All
    = Init
    | InitAuthorized
    | InitUnauthorized
    | InitError
    | SignInEmail
    | SignInEmailSuccess
    | SignInGoogle
    | SignInGoogleSuccess
    | SignInGoogleError
    | SignInFacebook
    | SignInFacebookSuccess
    | SignInFacebookError
    | SignInEmailError
    | SignUpEmail
    | SignUpEmailSuccess
    | SignUpEmailError
    | SignOut
    | SignOutSuccess
    | SignOutError
