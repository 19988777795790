import * as fromActions from './payment-details.actions';

export interface PaymentDetailsState {
    details: any;
    loading: boolean;
    error: string;
}

const initialState: PaymentDetailsState = {
    details: null,
    loading: null,
    error: null,
}


export function reducer(state: PaymentDetailsState = initialState, action: fromActions.All) {
    switch (action.type) { 

        case fromActions.Types.SET_PAYMENT_DETAILS: {
            return { ...state, details: action.details };
        }

        case fromActions.Types.UPDATE_PAYMENT_STATUS: {
            return { ...state, details: action.details, loading: true, error: null };
        }

        case fromActions.Types.UPDATE_PAYMENT_STATUS_SUCCESS: {
            return { ...state, loading: false, error: null };
        }

        case fromActions.Types.UPDATE_PAYMENT_STATUS_ERROR: {
            return { ...state, loading: false, error: action.error };
        }

        default: {
            return state;
        }
    }
}
