import { Post } from "@app/models/post.model";
import { createAction, props } from "@ngrx/store";
import {GetPostsRequest, SearchPostsRequest} from "./posts-paging.models";

export const getFirstPosts = createAction(
  '[Posts] First Posts',
  props<GetPostsRequest>()
)

export const getFirstPostsSuccess = createAction(
  '[Posts] First Posts Success',
  props<{total: number, firstPost: any, lastPost: any, postsByPage: Post[]}>()
)

export const getFirstPostsFailure = createAction(
  '[Posts] First Posts Failure',
  props<{error: any}>()
)

export const getNextPosts = createAction(
  '[Posts] Next Posts',
  props<GetPostsRequest>()
)

export const getNextPostsSuccess = createAction(
  '[Posts] Next Posts Success',
  props<{total: number, firstPost: any, lastPost: any, postsByPage: Post[]}>()
)

export const getNextPostsFailure = createAction(
  '[Posts] Next Posts Failure',
  props<{error: any}>()
)

export const getPreviousPosts = createAction(
  '[Posts] Previous Posts',
  props<GetPostsRequest>()
)

export const getPreviousPostsSuccess = createAction(
  '[Posts] Previous Posts Success',
  props<{total: number, firstPost: any, lastPost: any, postsByPage: Post[]}>()
)

export const getPreviousPostsFailure = createAction(
  '[Posts] Previous Posts Failure',
  props<{error: any}>()
)

export const searchPosts = createAction(
    '[Posts] Search Posts',
    props<SearchPostsRequest>()
)

export const searchPostsSuccess = createAction(
    '[Posts] Search Posts Success',
    props<{total: number, firstPost: any, lastPost: any, postsByPage: Post[]}>()
)

export const searchPostsFailure = createAction(
    '[Posts] Search Posts Failure',
    props<{error: any}>()
)

