import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductState } from "./products.reducers";

export const getProductState = createFeatureSelector<ProductState>('product');

export const getProducts = createSelector(
  getProductState,
  (state) => state.products
)

export const getSetOptions = createSelector(
  getProductState,
  (state) => state.options
)

export const getOrder = createSelector(
  getProductState,
  (state) => state.order
);

export const getLoading = createSelector(
  getProductState,
  (state) => state.loading
);
export const getSuccessOrderInfo = createSelector(
  getProductState,
  (state) => state.successOrderInfo
);

export const getIsSuccess = createSelector(
  getProductState,
(state) => state.isSuccess
);

export const getCheckoutDetails = createSelector(
  getProductState,
(state) => state.checkoutDetails
);
