import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { from, of } from 'rxjs';
import { map, switchMap, catchError, take, mergeMap } from 'rxjs/operators';

import * as fromActions from './products.actions';

import { NotificationService } from '@app/services';
import { ProductService } from "@app/services/product.service";
import { Store } from "@ngrx/store";
import * as fromProduct from 'app/store/product';
import { OrdersService } from "@app/services/orders.service";
@Injectable ()

export class ProductEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    public productService: ProductService,
    private ordersService: OrdersService,
    public store: Store,
  ) { }

  public read = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_PRODUCTS),
          map((action: fromActions.GetProducts) => action.merchantId),
          mergeMap((payload) => {
                take(1)
                return this.productService.getProducts(payload).pipe(
                map((products) => new fromActions.GetProductsSuccess(products)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetProductsError(err.message))})
              );
            })
      )
  })

  public search = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.SEARCH_PRODUCT),
      map((action: fromActions.SearchProduct) => action.request),
      switchMap((request) => {
        return this.productService.searchProduct(request).pipe(
          map(products => new fromActions.SearchProductSuccess(products)),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.SearchProductError(err.message))
          })
        )
      })
    )
  })

  public updateInventory = createEffect(() =>
  this.actions.pipe(
    ofType(fromActions.Types.UPDATE_INVENTORY),
    map((action: fromActions.UpdateInventory) => action.orderData),
    switchMap(orderData =>
      from(this.productService.updateInventory(orderData)).pipe(
        map(() => {
            return new fromActions.UpdateInventorySuccess()}),
        catchError(err => {
            return of(new fromActions.UpdateInventoryError(err.message))
        })
      )
    )
  ));

  public submitOrder$ = createEffect(() =>
  this.actions.pipe(
   ofType(fromActions.Types.SUBMIT_PRODUCT_ORDER),
   map((action: fromActions.SubmitOrder) => action.order),
   switchMap((order) =>
    from(this.ordersService.createOrder(order.order)).pipe(
        map((orderId) => {
            this.ordersService.setOrderItems(order.order, orderId)
            order.order.items.forEach((orderItems) => {
              this.store.dispatch(new fromProduct.UpdateInventory({merchantId: order.order.merchantId, productId: orderItems.id, quantity: orderItems.orderQuantity}))
            })
            return new fromActions.SubmitOrderSuccess({order: order.order, orderId: orderId, isSuccess: true})
        }),
        catchError(err => {
            return of(new fromActions.SubmitOrderError(err.message))})
        )),
      catchError(err => {
          return of(new fromActions.SubmitOrderError(err.message))
      })
      )
    )

    public checkoutPay$ = createEffect(() =>
    this.actions.pipe(ofType(fromActions.Types.CHECK_OUT_ORDER_PAY),
        map((action: fromActions.CheckOutOrderPay) => action.orders),
        switchMap(order => from(this.ordersService.createOrder(order.order)).pipe(
          map((updatedOrder) => new fromActions.CheckOutOrderSuccess({...order, order: updatedOrder, isSuccess: true})),
          catchError(err => {
              return of(new fromActions.CheckOutOrderError(err.message))
          })
          )
        )
    ));

}
