import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, catchError, take, mergeMap, switchMap } from 'rxjs/operators';
import * as fromActions from './order.actions';
import { NotificationService } from '@app/services';
import { OrdersService } from "@app/services/orders.service";
import {OrdersRepository} from "@app/repository/orders.repository";

@Injectable ()
export class OrderEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    private ordersService: OrdersService,
    private ordersRepository: OrdersRepository
  ) { }

  public readMyOrders = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_MY_ORDERS),
          map((action: fromActions.GetMyOrders) => action.data),
          mergeMap((payload) => {
                take(1)
                return this.ordersService.getMyOrders(payload).pipe(
                map((orders) => new fromActions.GetMyOrdersSuccess(orders)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetMyOrdersError(err.message))})
              );
            })
      )
  })

  public readOrderItems = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_ORDER_ITEMS),
          map((action: fromActions.GetOrderItems) => action.orderId),
          mergeMap((orderId) => {
                take(1)
                return this.ordersService.getOrderItems(orderId).pipe(
                map((orderItems) => new fromActions.GetOrderItemsSuccess(orderItems)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetOrderItemsError(err.message))})
              );
            })
      )
  })

  public readOrderByOrderId = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_MY_ORDERS_BY_ORDER_ID),
          map((action: fromActions.GetOrdersByOrderId) => action.orderId),
          mergeMap((orderId) => {
                take(1)
                return this.ordersService.getOrderById(orderId).pipe(
                map((orders) => new fromActions.GetOrdersByOrderIdSuccess(orders)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetOrdersByOrderIdError(err.message))})
              );
            })
      )
  })

    public readProductOrderById = createEffect(() => {
        return this.actions.pipe(
            ofType(fromActions.Types.GET_PRODUCT_ORDER_BY_ID),
            map((action: fromActions.GetProductOrderById) => action.request),
            switchMap((request) => {
                return this.ordersRepository.getProductOrderById(request.merchantId, request.orderId).pipe(
                    map((order) => new fromActions.GetProductOrderByIdSuccess(order)),
                    catchError(err => {
                        this.notification.error(err.message);
                        return of(new fromActions.GetProductOrderByIdError(err.message))})
                );
            })
        )
    })

    public readPQuotationOrderById = createEffect(() => {
        return this.actions.pipe(
            ofType(fromActions.Types.GET_QUOTATION_ORDER_BY_ID),
            map((action: fromActions.GetQuotationOrderById) => action.request),
            switchMap((request) => {
                return this.ordersRepository.getQuotationOrderByIdAsync(request.merchantId, request.orderId).pipe(
                    map((order) => new fromActions.GetQuotationOrderByIdSuccess(order)),
                    catchError(err => {
                        this.notification.error(err.message);
                        return of(new fromActions.GetQuotationOrderByIdError(err.message))})
                );
            })
        )
    })

  public readQuotationOrderByOrderId = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_QUOTATION_ORDERS_BY_ORDER_ID),
          map((action: fromActions.GetQuotationOrdersByOrderId) => action.params),
          switchMap((params) => {
                return this.ordersService.getQuotationOrderById(params).pipe(
                map((orders) => new fromActions.GetQuotationOrdersByOrderIdSuccess(orders)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetQuotationOrdersByOrderIdError(err.message))})
              );
            })
      )
  })

  public readQuotationOrderItems = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_QUOTATION_ORDER_ITEMS),
          map((action: fromActions.GetQuotationOrderItems) => action.orderId),
          switchMap((orderId) => {
                return this.ordersService.getQuotationOrderItems(orderId).pipe(
                map((orderItems) => new fromActions.GetQuotationOrderItemsSuccess(orderItems)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetQuotationOrderItemsError(err.message))})
              );
            })
      )
  })

  public readQuotations = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_MY_QUOTATIONS),
      map((action: fromActions.GetMyQuotations) => action.data),
      switchMap((payload) => {
        return this.ordersService.getMyQuotations(payload).pipe(
          map((orders) => new fromActions.GetMyQuotationsSuccess(orders)),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.GetMyQuotationsError(err.message))
          })
        );
      })
    );
  })

  public updateOrder = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.UPDATE_ORDER),
      map((action: fromActions.UpdateOrder) => action.orderData),
      switchMap(orderData => {
        return from(this.ordersService.updateOrder(orderData)).pipe(
          map(() => new fromActions.UpdateOrderSuccess()),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.UpdateOrderError(err.message));
          })
        )
      })
    )
  })

  public updateOrderSchedule = createEffect(() => {
    return this.actions.pipe(
        ofType(fromActions.Types.UPDATE_ORDER_SCHEDULE),
        map((action: fromActions.UpdateOrder) => action.orderData),
        switchMap(orderData => {
            return from(this.ordersService.updateOrder(orderData)).pipe(
                map(() => new fromActions.UpdateOrderScheduleSuccess()),
                catchError(err => {
                    this.notification.error(err.message);
                    return of(new fromActions.UpdateOrderScheduleError(err.message));
                })
            )
        })
    )
  })

  public deleteOrder = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.DELETE_ORDER),
      map((action: fromActions.DeleteOrder) => action.data),
      switchMap(data =>
        from(this.ordersService.deleteOrder(data)).pipe(
          map(() => new fromActions.DeleteOrderSuccess()),
          catchError(err => {
            this.notification.error(err.message)
            return of(new fromActions.DeleteOrderError(err.message))
          })
        )
      )
    )
  })

  public readOrderInvoices = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_QUOTATION_INVOICES),
      map((action: fromActions.GetOrderInvoices) => action.params),
      switchMap((params) => {
        return this.ordersService.getOrderInvoices(params).pipe(
          map((invoices) => new fromActions.GetOrderInvoicesSuccess(invoices)),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.GetOrderInvoicesError(err.message))
          })
        );
      })
    )
  });

}
