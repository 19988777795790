import { Action } from '@ngrx/store';
import { searchRequest, updateInventoryRequest } from './products.models';
import { successOrderInfo } from './products.models';
import { Product } from '@app/models/product.model';

export enum Types {
  GET_PRODUCTS = '[Product] Get products: Start',
  GET_PRODUCTS_SUCCESS = '[Product] Get products: Success',
  GET_PRODUCTS_ERROR = '[Product] Get products: Error',

  SEARCH_PRODUCT = '[Product] Search Product: Start',
  SEARCH_PRODUCT_SUCCESS = '[Product] Search Product: Success',
  SEARCH_PRODUCT_ERROR = '[Product] Search Product: Error',

  UPDATE_INVENTORY = '[ Update inventory ] Update inventory: Start',
  UPDATE_INVENTORY_SUCCESS = '[ Update inventory ] Update inventory: Success',
  UPDATE_INVENTORY_ERROR = '[ Update inventory ] Update inventory: Error',

  SET_OPTIONS = '[Option] Set options: Start',
  CLEAR_SET_OPTIONS = '[Option] Clear set options: Start',

  SUBMIT_PRODUCT_ORDER = '[Order] Submit Product Order: Start',
  SUBMIT_PRODUCT_ORDER_SUCCESS = '[Order] Submit Product Order: Success',
  SUBMIT_PRODUCT_ORDER_ERROR = '[Order] Submit Product Order: Error',

  CHECK_OUT_ORDER_PAY = '[Order check-out pay] Checkout orders: Start',
  CHECK_OUT_ORDER_SUCCESS = '[Order check-out] Checkout orders: Success',
  CHECK_OUT_ORDER_ERROR = '[Order check-out] Checkout orders: Error',

  IS_NEW_ORDER = '[Order] Set is new order',
  CLEAR_DATA = '[Checkout] Clear checkout data: Start',

  RESET_DATA = '[SignOut] Clear Products Data',
}

export class GetProducts implements Action {
  readonly type = Types.GET_PRODUCTS;
  constructor(public merchantId: string) { }
}

export class GetProductsSuccess implements Action {
  readonly type = Types.GET_PRODUCTS_SUCCESS;
  constructor( public products: any) { }
}

export class GetProductsError implements Action {
  readonly type = Types.GET_PRODUCTS_ERROR;
  constructor(public error: string) { }
}

export class SearchProduct implements Action {
  readonly type = Types.SEARCH_PRODUCT;
  constructor(public request: searchRequest) { }
}

export class SearchProductSuccess implements Action {
  readonly type = Types.SEARCH_PRODUCT_SUCCESS;
  constructor(public products: Product []) { }
}

export class SearchProductError implements Action {
  readonly type = Types.SEARCH_PRODUCT_ERROR;
  constructor(public error: string) { }
}

export class UpdateInventory implements Action {
  readonly type = Types.UPDATE_INVENTORY ;
  constructor(public orderData: updateInventoryRequest) { }
}
export class UpdateInventorySuccess implements Action {
  readonly type = Types.UPDATE_INVENTORY_SUCCESS;
  constructor() { }
}

export class UpdateInventoryError implements Action {
  readonly type = Types.UPDATE_INVENTORY_ERROR;
  constructor(public error: string) { }
}

export class SetOptions implements Action {
  readonly type = Types.SET_OPTIONS;
  constructor(public options: any) { }
}

export class ClearSetOptions implements Action {
  readonly type = Types.CLEAR_SET_OPTIONS;
  constructor() { }
}

export class CheckOutOrderPay implements Action {
  readonly type = Types.CHECK_OUT_ORDER_PAY;
  constructor(public orders: any) { }
}

export class CheckOutOrderSuccess implements Action {
  readonly type = Types.CHECK_OUT_ORDER_SUCCESS;
  constructor(public checkoutDetails: any) { }
}

export class CheckOutOrderError implements Action {
  readonly type = Types.CHECK_OUT_ORDER_ERROR;
  constructor(public error: string) { }
}

export class SubmitOrder implements Action {
  readonly type = Types.SUBMIT_PRODUCT_ORDER;
  constructor(public order: any) { }
}
export class SubmitOrderSuccess implements Action {
  readonly type = Types.SUBMIT_PRODUCT_ORDER_SUCCESS;
  constructor(public successOrderInfo: successOrderInfo) { }
}

export class SubmitOrderError implements Action {
  readonly type = Types.SUBMIT_PRODUCT_ORDER_ERROR;
  constructor(public error: string) { }
}
export class SetIsNewOrder implements Action {
  readonly type = Types.IS_NEW_ORDER;
  constructor(){}
}

export class ClearCheckoutData implements Action {
  readonly type = Types.CLEAR_DATA;
  constructor() { }
}

export class ResetData implements Action {
  readonly type = Types.RESET_DATA;
  constructor() { }
}

export type All
    = GetProducts
    | GetProductsSuccess
    | GetProductsError
    | SearchProduct
    | SearchProductSuccess
    | SearchProductError
    | UpdateInventory
    | UpdateInventorySuccess
    | UpdateInventoryError
    | SetOptions
    | ClearSetOptions
    | CheckOutOrderPay
    | CheckOutOrderSuccess
    | CheckOutOrderError
    | SubmitOrder
    | SubmitOrderSuccess
    | SubmitOrderError
    | SetIsNewOrder
    | ClearCheckoutData
    | ResetData
