
import * as fromActions from './payment.actions';
import { SuccessInvoiceInfo } from './payment.model';

export interface PaymentState {
    getLoading: boolean
    error: string;
    paymentOptionInfo: any;
    loading: boolean;
    successInvoiceInfo: SuccessInvoiceInfo;
    successPaymentReceiptInfo: string;
}
const initialState: PaymentState = {
    getLoading:null,
    error: null,
    paymentOptionInfo: null,
    loading: false,
    successInvoiceInfo: null,
    successPaymentReceiptInfo: null
}
export function reducer(
    state: PaymentState = initialState, action: fromActions.All) {
    switch (action.type) {

      case fromActions.Types.READ_PAYMENT_OPTIONS: {
          return { ...state, getLoading: true, error: null};
      }
      case fromActions.Types.READ_PAYMENT_OPTIONS_SUCCESS: {
          return { ...state, paymentOptionInfo: action.paymentOptionInfo, getLoading: false, error: null};
      }
      case fromActions.Types.READ_PAYMENT_OPTIONS_ERROR: {
          return { ...state, getLoading: false, error: action.error };
      }

      case fromActions.Types.CREATE_INVOICE: {
          return { ...state, loading: true, successInvoiceInfo: null, error: null};
      }

      case fromActions.Types.CREATE_INVOICE_SUCCESS: {
          return {...state, loading: false, successInvoiceInfo: action.successInvoiceInfo, error: null}
      }

      case fromActions.Types.CREATE_INVOICE_ERROR: {
          return {...state, loading: false, successInvoiceInfo: null, error: action.error};
      }

      case fromActions.Types.UPLOAD_PAYMENT_RECEIPT_SUCCESS: {
        return {...state, loading: false, successPaymentReceiptInfo: action.url, error: null}
      }

      case fromActions.Types.UPLOAD_PAYMENT_RECEIPT_ERROR: {
        return {...state, loading: false, successPaymentReceiptInfo: null, error: action.error};
      }

      default: {
          return state;
      }
    }
}
