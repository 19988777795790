import {ProductOrder, QuotationOrder} from '@app/models/order.model';
import { Quotation } from '@app/models/quotation.model';
import * as fromActions from '@app/store/orders';
import {Invoice} from "@app/models/payment/invoice";
export interface OrderState {
  orders: any[];
  loading: boolean;
  error: string;
  orderitems: any[];
  ordersById: any[];
  quotations: any[];
  updateSuccess: boolean;
  updateScheduleSuccess: boolean;
  deleteSuccess: boolean;
  myOrders: any [];
  quotationOrdersById: QuotationOrder;
  quotationOrderItems: Quotation[];
  invoices: Invoice[];
  productOrder: ProductOrder;
  quotationOrder: QuotationOrder;
}

const initialState: OrderState = {
  orders: [],
  loading: false,
  error: null,
  orderitems: [],
  ordersById: null,
  quotations: [],
  updateSuccess: false,
  updateScheduleSuccess: false,
  deleteSuccess: false,
  myOrders: null,
  quotationOrdersById: null,
  quotationOrderItems: null,
  invoices: [],
  productOrder: null,
  quotationOrder: null
}

export function reducer(state: OrderState = initialState, action: fromActions.All){
  switch(action.type) {
    case fromActions.Types.GET_MY_ORDERS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_MY_ORDERS_SUCCESS: {
        return { ...state, loading: false, myOrders: action.myOrders, error: null };
    }
    case fromActions.Types.GET_MY_ORDERS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_ORDER_ITEMS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_ORDER_ITEMS_SUCCESS: {
        return { ...state, loading: false, orderitems: action.orderItems, error: null };
    }
    case fromActions.Types.GET_ORDER_ITEMS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_MY_ORDERS_BY_ORDER_ID: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_MY_ORDERS_BY_ORDER_ID_SUCCESS: {
        return { ...state, loading: false, ordersById: action.myOrders, error: null };
    }
    case fromActions.Types.GET_MY_ORDERS_BY_ORDER_ID_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_PRODUCT_ORDER_BY_ID: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_PRODUCT_ORDER_BY_ID_SUCCESS: {
      return { ...state, loading: false, productOrder: action.productOrder, error: null };
    }
    case fromActions.Types.GET_PRODUCT_ORDER_BY_ID_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_QUOTATION_ORDER_BY_ID: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_QUOTATION_ORDER_BY_ID_SUCCESS: {
      return { ...state, loading: false, quotationOrder: action.quotationOrder, error: null };
    }
    case fromActions.Types.GET_QUOTATION_ORDER_BY_ID_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_MY_QUOTATIONS: {
      return { ...state, loading: true, error: null};
    }
    case fromActions.Types.GET_MY_QUOTATIONS_SUCCESS: {
      return { ...state, loading: false, quotations: action.orders, error: null};
    }
    case fromActions.Types.GET_MY_QUOTATIONS_ERROR: {
      return { ...state, loading: false, error: action.error};
    }

    case fromActions.Types.UPDATE_ORDER: {
      return {...state, loading: true, updateSuccess: false, error: null};
    }
    case fromActions.Types.UPDATE_ORDER_SUCCESS: {
      return {...state, loading: false, updateSuccess: true, error: null};
    }
    case fromActions.Types.UPDATE_ORDER_ERROR: {
      return {...state, loading: false, updateSuccess: false, error: action.error};
    }

    case fromActions.Types.UPDATE_ORDER_SCHEDULE: {
      return {...state, loading: true, updateScheduleSuccess: false, error: null};
    }
    case fromActions.Types.UPDATE_ORDER_SCHEDULE_SUCCESS: {
      return {...state, loading: false, updateScheduleSuccess: true, error: null};
    }
    case fromActions.Types.UPDATE_ORDER_SCHEDULE_ERROR: {
      return {...state, loading: false, updateScheduleSuccess: false, error: action.error};
    }

    case fromActions.Types.DELETE_ORDER: {
      return {...state, loading: true, error: null, deleteSuccess: false}
    }
    case fromActions.Types.DELETE_ORDER_SUCCESS: {
      return {...state, loading: false, error: null, deleteSuccess: true}
    }
    case fromActions.Types.DELETE_ORDER_ERROR: {
      return {...state, loading: false, error: action.error, deleteSuccess: false}
    }

    case fromActions.Types.CLEAR_ORDERS: {
      return {...state, loading: false, orderitems: null, quotationOrderItems: null}
    }

    case fromActions.Types.CLEAR_MY_ORDER_DATA: {
      return {...state, loading: false, myOrders: null}
    }

    case fromActions.Types.GET_QUOTATION_ORDERS_BY_ORDER_ID: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_QUOTATION_ORDERS_BY_ORDER_ID_SUCCESS: {
        return { ...state, loading: false, quotationOrdersById: action.quotationOrders, error: null };
    }
    case fromActions.Types.GET_QUOTATION_ORDERS_BY_ORDER_ID_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_QUOTATION_ORDER_ITEMS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_QUOTATION_ORDER_ITEMS_SUCCESS: {
        return { ...state, loading: false, quotationOrderItems: action.quotationOrderItems, error: null };
    }
    case fromActions.Types.GET_QUOTATION_ORDER_ITEMS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.RESET_DATA: {
      return {...initialState}
    }
    case fromActions.Types.GET_QUOTATION_INVOICES: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_QUOTATION_INVOICES_SUCCESS: {
      return { ...state, loading: false, invoices: action.invoices, error: null };
    }
    case fromActions.Types.GET_QUOTATION_INVOICES_ERROR: {
      return { ...state, loading: false, error: action.error };
    }
    default: {
      return state;
    }
  }
}
