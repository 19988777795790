import { Injectable } from '@angular/core';
import {map, Observable, of, take} from "rxjs";
import {DbPath} from "@app/shared/utils/db-path";
import {Constants} from "@app/shared/utils/constant";
import {AngularFirestore} from "@angular/fire/compat/firestore";

@Injectable({
  providedIn: 'root'
})
export class BillsService {

  constructor(
      private afs: AngularFirestore,
  ) { }

  getMyBills(merchantId: string, customerId: string): Observable<any[]>{
    if(customerId){
      return this.afs.collectionGroup<any>(DbPath.Orders, ref => ref
          .where('merchantId','==', merchantId)
          .where('customerInfo.customerId','==', customerId)
          .where('type','==',Constants.ORDER_TYPE.QUOTATION)
          .where('status','!=',Constants.DELETED)
          .orderBy('status', 'asc')
          .orderBy('createdAt', 'desc')
      ).get().pipe(
          take(1),
          map(snapshot => {
            return snapshot.docs.map(doc => {
              return ({id: doc.id, billId: doc.id, ...doc.data()});
            })
          })
      );
    }
    return of([])
  }
}
