import { Post } from "@app/models/post.model";
import * as PostActions from "./posts.actions"
import { createReducer, on } from "@ngrx/store";
import { PostElement } from "@app/models/post-element.model";

export interface PostState {
    post: Post | null;
    posts: Post [] | null;
    elements: PostElement[] | null;
    error: any | null;
}

export const initialState: PostState = {
    post: null,
    posts: null,
    elements: null,
    error: null
}

export const reducer = createReducer(
    initialState,
    on(PostActions.listPostsSuccess, (state, { posts }) => ({...state, posts})),
    on(PostActions.listPostsFailure, (state, { error }) => ({...state, error})),

    on(PostActions.getPostSuccess, (state, { post }) => ({...state, post})),
    on(PostActions.getPostFailure, (state, { error }) => ({...state, error})),
    on(PostActions.searchPostSuccess, (state, { posts }) => ({...state, posts})),
    on(PostActions.searchPostFailure, (state, { error }) => ({...state, error})),

    on(PostActions.readElementsSuccess, (state, { elements }) => ({...state, elements})),
    on(PostActions.readElementsFailure, (state, { error }) => ({...state, error})),
)
