import {Injectable} from '@angular/core';
import {Cart, CartItem} from "@app/models/cart.model";
import {DbPath} from "@app/shared/utils/db-path";
import {AngularFirestore} from "@angular/fire/compat/firestore";
import {Constants} from "@app/shared/utils/constant";

@Injectable({
  providedIn: 'root'
})
export class CartService {
  constructor(
      private afs: AngularFirestore,
  ) {}


  async addItemQuantity(merchantId: string, cart: Cart, cartItem: CartItem){
    return this.setItemQuantity(cart, cartItem, +1, merchantId);
  }

  async subtractItemQuantity(merchantId: string, cart: Cart, cartItem: CartItem){
    return this.setItemQuantity(cart, cartItem, -1, merchantId);
  }

  async setItemQuantity(cart: Cart, cartItem: CartItem, quantity: number, merchantId: string): Promise<Cart> {
    let existingItemIndex = cart.items?.findIndex((_item: any) => _item.id === cartItem.id)
    let newCartItems = [...cart.items];
    const existingItem = newCartItems[existingItemIndex];
    const newQuantity = existingItem.quantity + quantity;
    newCartItems[existingItemIndex] = CartItem.createWithQuantity(newQuantity, existingItem);
    await this.updateCartItemData(newCartItems[existingItemIndex], cart.id, merchantId);
    return Cart.create(cart.id, newCartItems);
  }

  async removeFromCart(merchantId: string, cart: Cart, cartItem: CartItem): Promise<Cart>{
    let indexItem = cart?.items?.findIndex((_item: any) => _item?.id == cartItem.id)
    let newCartItems = [...cart.items];
    const cartItemId = newCartItems[indexItem].id;
    await this.deleteCartItemData(cartItemId, cart.id, merchantId);
    newCartItems.splice(indexItem, 1)
    return Cart.create(cart.id, newCartItems);
  }

  async addToCart(merchantId: string, cart: Cart, cartItem: CartItem) : Promise<Cart> {
    const newCartItems = this.updateCartItems(cart, cartItem);
    return await this.upsertCartData(
        Cart.create(cart?.id, newCartItems),
        merchantId);
  }

  updateCartItems(cart: Cart, cartItem: CartItem): CartItem[] {
      let newCartItems = cart ? [...cart.items] : [];
      let existingItemIndex = newCartItems.findIndex((_item: any) => _item?.productId == cartItem.productId)
      if(existingItemIndex > -1) {
          let existingItem = newCartItems[existingItemIndex];
          if(cartItem.options?.length) {
              let itemWithSameOptionIndex = newCartItems?.findIndex((_item: CartItem) => {
                  if(_item.options.length != cartItem.options.length) {
                      return false;
                  }
                  for (const option of _item.options) {
                      if(!cartItem.options.some((_option) => _option.code === option.code)){
                          return false;
                      }
                  }
                  return true;
              });
              if(itemWithSameOptionIndex > -1) {
                  existingItem = newCartItems[itemWithSameOptionIndex];
                  const newQuantity = existingItem.quantity + 1
                  newCartItems[itemWithSameOptionIndex] = CartItem.createWithQuantity(newQuantity, existingItem);
              } else {
                  newCartItems.push(cartItem);
              }
          } else {
              const newQuantity = existingItem.quantity + 1
              newCartItems[existingItemIndex] = CartItem.createWithQuantity(newQuantity, existingItem);
          }
      } else {
          newCartItems.push(cartItem);
      }
      return newCartItems;
  }

  async upsertCartData(cart: Cart, merchantId: string): Promise<Cart> {
    if(!cart.id){
      return await this.insertCartData(cart, merchantId);
    } else {
      return await this.updateCartData(cart, merchantId);
    }
  }

  async insertCartData(cart: Cart, merchantId: string): Promise<Cart> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Carts)
        .add({total: cart.total, itemsLength: cart.items.length, ordered: false, createdAt: new Date()}).then(
        async docRef => {
            const cartId = docRef.id;
            let newOrUpdatedCartItems: CartItem[] = [];
            for (const cartItem of cart.items) {
                const newOrUpdatedCartItem = await this.upsertCartItemData(cartItem, cartId, merchantId);
                newOrUpdatedCartItems.push(newOrUpdatedCartItem);
            }
            return Cart.create(cartId, newOrUpdatedCartItems);
        }
    );
  }

  async updateCartData(cart: Cart, merchantId: string): Promise<Cart> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Carts).doc(cart.id)
        .set({total: cart.total, itemsLength: cart.items.length, updatedAt: new Date()}, { merge: true })
        .then(async () => {
            let newOrUpdatedCartItems: CartItem[] = [];
            for (const cartItem of cart.items) {
                const newOrUpdatedCartItem = await this.upsertCartItemData(cartItem, cart.id, merchantId);
                newOrUpdatedCartItems.push(newOrUpdatedCartItem);
            }
            return Cart.create(cart.id, newOrUpdatedCartItems);
        });
  }

  async upsertCartItemData(cartItem: CartItem, cartId: string, merchantId: string): Promise<CartItem> {
    if(!cartItem.id){
      return await this.insertCartItemData(cartItem, cartId, merchantId);
    } else {
      return await this.updateCartItemData(cartItem, cartId, merchantId);
    }
  }

  async insertCartItemData(cartItem: CartItem, cartId: string, merchantId: string): Promise<CartItem> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Carts).doc(cartId).collection(DbPath.CartItems)
        .add({...cartItem.toData(), merchantId: merchantId, cartId: cartId, createdAt: new Date()}).then(
            docRef => {
              return CartItem.createWithIdAndCartId(docRef.id, cartId, cartItem);
            }
    );
  }

  async updateCartItemData(cartItem: CartItem, cartId: string, merchantId: string): Promise<CartItem> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Carts).doc(cartId).collection(DbPath.CartItems)
        .doc(cartItem.id)
        .set({...cartItem.toData(), updatedAt: new Date()}, { merge: true }).then(
            () => {
              return cartItem;
            }
        );
  }

  async deleteCartItemData(cartItemId: string, cartId: string, merchantId: string){
      await this.afs.collection(DbPath.Merchants).doc(merchantId)
          .collection(DbPath.Carts).doc(cartId).collection(DbPath.CartItems)
          .doc(cartItemId).update(
              {
                  'deletedAt': new Date(),
                  'status': Constants.DELETED
              }
          );
  }

  async setCartToOrderedData(cartId: string, merchantId: string): Promise<void> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Carts).doc(cartId)
      .set({ordered: true, updatedAt: new Date()}, { merge: true });
  }
}
