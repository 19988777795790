import * as fromActions from '@app/store/inquiries';
export interface InquiriesState {
  loading: boolean;
  error: string;
  isSuccess: boolean;
}

const initialState: InquiriesState = {
  loading: false,
  error: null,
  isSuccess: false,
}

export function reducer(state: InquiriesState = initialState, action: fromActions.All){
  switch(action.type) {
    case fromActions.Types.CREATE_INQUIRIES: {
      return { ...state, loading: true, error: null, };
    }
    case fromActions.Types.CREATE_INQUIRIES_SUCCESS: {
        return { ...state, loading: false, error: null, isSuccess: true, };
    }
    case fromActions.Types.CREATE_INQUIRIES_ERROR: {
        return { ...state, loading: false, error: action.error, isSuccess: false, };
    }

    case fromActions.Types.SET_INQUIRIES_FALSE_IS_SUCCESS: {
      return { ...state, loading: false, isSuccess: null, };
    }


    default: {
      return state;
    }
  }
}
