import { Action } from '@ngrx/store';
import {
  deleteRequest,
  readOrderDetailRequest,
  readOrderInvoiceRequest,
  readOrderRequest,
  readRequest,
  updateStatusRequest
} from './order.models';
import {Invoice} from "@app/models/payment/invoice";
import {ProductOrder, QuotationOrder} from "@app/models/order.model";

export enum Types {
  GET_MY_ORDERS = '[My Orders] Get my order: Start',
  GET_MY_ORDERS_SUCCESS = '[My Orders] Get my order: Success',
  GET_MY_ORDERS_ERROR = '[My Orders] Get my order: Error',

  GET_ORDER_ITEMS = '[Order Items] Get order items: Start',
  GET_ORDER_ITEMS_SUCCESS = '[Order Items] Get order items: Success',
  GET_ORDER_ITEMS_ERROR = '[Order Items] Get order items: Error',

  GET_MY_ORDERS_BY_ORDER_ID = '[My Orders] Get my order by order id: Start',
  GET_MY_ORDERS_BY_ORDER_ID_SUCCESS = '[My Orders] Get my order by order id: Success',
  GET_MY_ORDERS_BY_ORDER_ID_ERROR = '[My Orders] Get my order by order id: Error',

  GET_MY_QUOTATIONS = '[My Quotations] Get my quotations: Start',
  GET_MY_QUOTATIONS_SUCCESS = '[My Quotations] Get my quotations: Success',
  GET_MY_QUOTATIONS_ERROR = '[My Quotations] Get my quotations: Error',

  UPDATE_ORDER = '[Update Order] Update Order: Start',
  UPDATE_ORDER_SUCCESS = '[Update Order] Update Order: Success',
  UPDATE_ORDER_ERROR = '[Update Order] Update Order: Error',

  UPDATE_ORDER_SCHEDULE = '[Update Order Schedule] Update Order Schedule: Start',
  UPDATE_ORDER_SCHEDULE_SUCCESS = '[Update Order Schedule] Update Order Schedule: Success',
  UPDATE_ORDER_SCHEDULE_ERROR = '[Update Order Schedule] Update Order Schedule: Error',

  DELETE_ORDER = '[Delete Order] Delete order: Start',
  DELETE_ORDER_SUCCESS = '[Delete Order] Delete order: Success',
  DELETE_ORDER_ERROR = '[Delete Order] Delete order: Error',

  CLEAR_ORDERS = '[Orders] Clear order: Start',

  CLEAR_MY_ORDER_DATA = '[My Orders] Clear my order: Start',

  GET_PRODUCT_ORDER_BY_ID = '[Product Order] Get product order by order id: Start',
  GET_PRODUCT_ORDER_BY_ID_SUCCESS = '[Product Order] Get product order by order id: Success',
  GET_PRODUCT_ORDER_BY_ID_ERROR = '[Product Order] Get product order by order id: Error',

  GET_QUOTATION_ORDER_BY_ID = '[Quotation Order] Get quotation order by order id: Start',
  GET_QUOTATION_ORDER_BY_ID_SUCCESS = 'Quotation Order] Get quotation order by order id: Success',
  GET_QUOTATION_ORDER_BY_ID_ERROR = '[Quotation Order] Get quotation order by order id: Error',

  GET_QUOTATION_ORDERS_BY_ORDER_ID = '[My Orders] Get quotation order by order id: Start',
  GET_QUOTATION_ORDERS_BY_ORDER_ID_SUCCESS = '[My Orders] Get quotation order by order id: Success',
  GET_QUOTATION_ORDERS_BY_ORDER_ID_ERROR = '[My Orders] Get quotation order by order id: Error',

  GET_QUOTATION_ORDER_ITEMS = '[Order Items] Get quotation order items: Start',
  GET_QUOTATION_ORDER_ITEMS_SUCCESS = '[Order Items] Get quotation order items: Success',
  GET_QUOTATION_ORDER_ITEMS_ERROR = '[Order Items] Get quotation order items: Error',

  RESET_DATA = '[SignOut] Clear Products Data',

  GET_QUOTATION_INVOICES = '[Quotation Invoices] Get quotation invoices: Start',
  GET_QUOTATION_INVOICES_SUCCESS = '[Quotation Invoices] Get quotation invoices: Success',
  GET_QUOTATION_INVOICES_ERROR = '[Quotation Invoices] Get quotation invoices: Error',

}


export class GetMyOrders implements Action {
  readonly type = Types.GET_MY_ORDERS;
  constructor(public data: readRequest) { }
}

export class GetMyOrdersSuccess implements Action {
  readonly type = Types.GET_MY_ORDERS_SUCCESS;
  constructor( public myOrders: any) { }
}

export class GetMyOrdersError implements Action {
  readonly type = Types.GET_MY_ORDERS_ERROR;
  constructor(public error: string) { }
}

export class GetOrderItems implements Action {
  readonly type = Types.GET_ORDER_ITEMS;
  constructor(public orderId: string) { }
}

export class GetOrderItemsSuccess implements Action {
  readonly type = Types.GET_ORDER_ITEMS_SUCCESS;
  constructor( public orderItems: any) { }
}

export class GetOrderItemsError implements Action {
  readonly type = Types.GET_ORDER_ITEMS_ERROR;
  constructor(public error: string) { }
}

export class GetOrdersByOrderId implements Action {
  readonly type = Types.GET_MY_ORDERS_BY_ORDER_ID;
  constructor(public orderId: string) { }
}

export class GetOrdersByOrderIdSuccess implements Action {
  readonly type = Types.GET_MY_ORDERS_BY_ORDER_ID_SUCCESS;
  constructor( public myOrders: any) { }
}

export class GetOrdersByOrderIdError implements Action {
  readonly type = Types.GET_MY_ORDERS_BY_ORDER_ID_ERROR;
  constructor(public error: string) { }
}

export class GetProductOrderById implements Action {
  readonly type = Types.GET_PRODUCT_ORDER_BY_ID;
  constructor(public request: readOrderRequest) { }
}

export class GetProductOrderByIdSuccess implements Action {
  readonly type = Types.GET_PRODUCT_ORDER_BY_ID_SUCCESS;
  constructor( public productOrder: ProductOrder) { }
}

export class GetProductOrderByIdError implements Action {
  readonly type = Types.GET_PRODUCT_ORDER_BY_ID_ERROR;
  constructor(public error: string) { }
}

export class GetQuotationOrderById implements Action {
  readonly type = Types.GET_QUOTATION_ORDER_BY_ID;
  constructor(public request: readOrderRequest) { }
}

export class GetQuotationOrderByIdSuccess implements Action {
  readonly type = Types.GET_QUOTATION_ORDER_BY_ID_SUCCESS;
  constructor( public quotationOrder: QuotationOrder) { }
}

export class GetQuotationOrderByIdError implements Action {
  readonly type = Types.GET_QUOTATION_ORDER_BY_ID_ERROR;
  constructor(public error: string) { }
}

export class GetMyQuotations implements Action {
  readonly type = Types.GET_MY_QUOTATIONS;
  constructor(public data: readRequest) { }
}

export class GetMyQuotationsSuccess implements Action {
  readonly type = Types.GET_MY_QUOTATIONS_SUCCESS;
  constructor(public orders: any) { }
}

export class GetMyQuotationsError implements Action {
  readonly type = Types.GET_MY_QUOTATIONS_ERROR;
  constructor(public error: string) { }
}

export class UpdateOrder implements Action {
  readonly type = Types.UPDATE_ORDER;
  constructor(public orderData: updateStatusRequest) { }
}

export class UpdateOrderSuccess implements Action {
  readonly type = Types.UPDATE_ORDER_SUCCESS;
  constructor() { }
}

export class UpdateOrderError implements Action {
  readonly type = Types.UPDATE_ORDER_ERROR;
  constructor(public error: string) { }
}

export class UpdateOrderSchedule implements Action {
  readonly type = Types.UPDATE_ORDER_SCHEDULE;
  constructor(public orderData: updateStatusRequest) { }
}

export class UpdateOrderScheduleSuccess implements Action {
  readonly type = Types.UPDATE_ORDER_SCHEDULE_SUCCESS;
  constructor() { }
}

export class UpdateOrderScheduleError implements Action {
  readonly type = Types.UPDATE_ORDER_SCHEDULE_ERROR;
  constructor(public error: string) { }
}

export class DeleteOrder implements Action {
  readonly type = Types.DELETE_ORDER;
  constructor(public data: deleteRequest) { }
}

export class DeleteOrderSuccess implements Action {
  readonly type = Types.DELETE_ORDER_SUCCESS;
  constructor() { }
}

export class DeleteOrderError implements Action {
  readonly type = Types.DELETE_ORDER_ERROR;
  constructor(public error: string) { }
}

export class ClearOrders implements Action {
  readonly type = Types.CLEAR_ORDERS;
  constructor() { }
}

export class ClearMyOrdersData implements Action {
  readonly type = Types.CLEAR_MY_ORDER_DATA;
  constructor() { }
}

export class GetQuotationOrdersByOrderId implements Action {
  readonly type = Types.GET_QUOTATION_ORDERS_BY_ORDER_ID;
  constructor(public params: readOrderDetailRequest) { }
}

export class GetQuotationOrdersByOrderIdSuccess implements Action {
  readonly type = Types.GET_QUOTATION_ORDERS_BY_ORDER_ID_SUCCESS;
  constructor( public quotationOrders: any) { }
}

export class GetQuotationOrdersByOrderIdError implements Action {
  readonly type = Types.GET_QUOTATION_ORDERS_BY_ORDER_ID_ERROR;
  constructor(public error: string) { }
}

export class GetQuotationOrderItems implements Action {
  readonly type = Types.GET_QUOTATION_ORDER_ITEMS;
  constructor(public orderId: string) { }
}

export class GetQuotationOrderItemsSuccess implements Action {
  readonly type = Types.GET_QUOTATION_ORDER_ITEMS_SUCCESS;
  constructor( public quotationOrderItems: any) { }
}

export class GetQuotationOrderItemsError implements Action {
  readonly type = Types.GET_QUOTATION_ORDER_ITEMS_ERROR;
  constructor(public error: string) { }
}

export class ResetData implements Action {
  readonly type = Types.RESET_DATA;
  constructor() { }
}
export class GetOrderInvoices implements Action {
  readonly type = Types.GET_QUOTATION_INVOICES;
  constructor(public params: readOrderInvoiceRequest) { }
}

export class GetOrderInvoicesSuccess implements Action {
  readonly type = Types.GET_QUOTATION_INVOICES_SUCCESS;
  constructor(public invoices: Invoice[]) { }
}

export class GetOrderInvoicesError implements Action {
  readonly type = Types.GET_QUOTATION_INVOICES_ERROR;
  constructor(public error: string) { }
}

export type All
    = GetMyOrders
    | GetMyOrdersSuccess
    | GetMyOrdersError
    | GetOrderItems
    | GetOrderItemsSuccess
    | GetOrderItemsError
    | GetOrdersByOrderId
    | GetOrdersByOrderIdSuccess
    | GetOrdersByOrderIdError
    | GetProductOrderById
    | GetProductOrderByIdSuccess
    | GetProductOrderByIdError
    | GetQuotationOrderById
    | GetQuotationOrderByIdSuccess
    | GetQuotationOrderByIdError
    | GetMyQuotations
    | GetMyQuotationsSuccess
    | GetMyQuotationsError
    | UpdateOrder
    | UpdateOrderSuccess
    | UpdateOrderError
    | UpdateOrderSchedule
    | UpdateOrderScheduleSuccess
    | UpdateOrderScheduleError
    | DeleteOrder
    | DeleteOrderSuccess
    | DeleteOrderError
    | ClearOrders
    | ClearMyOrdersData
    | GetQuotationOrdersByOrderId
    | GetQuotationOrdersByOrderIdSuccess
    | GetQuotationOrdersByOrderIdError
    | GetQuotationOrderItems
    | GetQuotationOrderItemsSuccess
    | GetQuotationOrderItemsError
    | ResetData
    | GetOrderInvoices
    | GetOrderInvoicesSuccess
    | GetOrderInvoicesError;
