import { createFeatureSelector, createSelector } from "@ngrx/store";
import { BillState } from "./bill.reducers";

export const getBillState = createFeatureSelector<BillState>('bill');

export const getBills = createSelector(
    getBillState,
  (state) => state.bills
)

export const getSelectedBill = createSelector(
    getBillState,
  (state) => state.selectedBill
)

export const getLoading = createSelector(
    getBillState,
  (state) => state.loading
);

export const getIsSuccess = createSelector(
    getBillState,
    (state) => state.isSuccess
);

export const getDeleteSuccess = createSelector(
    getBillState,
    (state) => state.deleteSuccess
)
