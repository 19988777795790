import { Product } from '@app/models/product.model';
import * as fromActions from '@app/store/product-paging';
export interface ProductPagingState {
  productsByPage: Product [];
  total: number;
  page: number;
  searchText: string;
  firstProduct: any;
  lastProduct: any;
  loading: boolean;
  error: string;
}

const initialState: ProductPagingState = {
  productsByPage: [],
  total: 0,
  page: 0,
  searchText: '',
  firstProduct: null,
  lastProduct: null,
  loading: false,
  error: null,
}

export function reducer(state: ProductPagingState = initialState, action: fromActions.All){
  switch(action.type) {

    case fromActions.Types.GET_FIRST_PRODUCTS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_FIRST_PRODUCTS_SUCCESS: {
        return { ...state, loading: false,
          total: action.total,
          firstProduct: action.firstProduct,
          lastProduct: action.lastProduct,
          productsByPage: action.products,
          error: null };
    }
    case fromActions.Types.GET_FIRST_PRODUCTS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_PREVIOUS_PRODUCTS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_PREVIOUS_PRODUCTS_SUCCESS: {
        return { ...state, loading: false,
          total: action.total,
          page: state.page - 1,
          firstProduct: action.firstProduct,
          lastProduct: action.lastProduct,
          productsByPage: action.products,
          error: null };
    }
    case fromActions.Types.GET_PREVIOUS_PRODUCTS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_NEXT_PRODUCTS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_NEXT_PRODUCTS_SUCCESS: {
        return { ...state, loading: false,
          total: action.total,
          page: state.page + 1,
          firstProduct: action.firstProduct,
          lastProduct: action.lastProduct,
          productsByPage: action.products,
          error: null };
    }
    case fromActions.Types.GET_NEXT_PRODUCTS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.SEARCH_PRODUCTS: {
      return { ...state, searchText: action.request.searchText, loading: true, error: null };
    }
    case fromActions.Types.SEARCH_PRODUCTS_SUCCESS: {
      return { ...state, loading: false,
        total: action.total,
        page: 0,
        firstProduct: action.firstProduct,
        lastProduct: action.lastProduct,
        productsByPage: action.products,
        error: null };
    }
    case fromActions.Types.SEARCH_PRODUCTS_ERROR: {
      return { ...state, loading: false, error: action.error };
    }

    default: {
      return state;
    }
  }
}
