import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DbPath } from '@app/shared/utils/db-path';
import { createInquiryRequest } from '@app/store/inquiries/inquiries.models';

@Injectable({
  providedIn: 'root'
})
export class InquiriesService {

  constructor(
    private afs: AngularFirestore,
  ) { }

  async createInquiries(payload: createInquiryRequest) : Promise<any> {
    return await this.afs.collection(DbPath.Merchants).doc(payload.merchantId).collection(DbPath.Inquiries).add(payload.inquiryInfo).then(docRef =>
      docRef.id
    );
  }
}
