import { Action } from '@ngrx/store';
import {createInvoiceRequest, SuccessInvoiceInfo, UploadPaymentReceiptRequest} from './payment.model';

export enum Types {

    READ_PAYMENT_OPTIONS = '[Payment] Read Payment info: Start',
    READ_PAYMENT_OPTIONS_SUCCESS = '[Payment] Read Payment info: Success',
    READ_PAYMENT_OPTIONS_ERROR = '[Payment] Read Payment info: Error',

    CREATE_INVOICE = '[Payment] Create Invoice: Start',
    CREATE_INVOICE_SUCCESS = '[Payment] Create Invoice: Success',
    CREATE_INVOICE_ERROR = '[Payment] Create Invoice: Error',

    UPLOAD_PAYMENT_RECEIPT = '[Payment] Upload Payment Receipt Screenshot info: Start',
    UPLOAD_PAYMENT_RECEIPT_SUCCESS = '[Payment] Upload Payment Receipt Screenshot info: Success',
    UPLOAD_PAYMENT_RECEIPT_ERROR = '[Payment] Upload Payment Receipt Screenshot info: Error',
}

export class ReadPaymentOptions implements Action {
    readonly type = Types.READ_PAYMENT_OPTIONS;
    constructor( public merchantId: string ) { }
}

export class ReadPaymentOptionsSuccess implements Action {
    readonly type = Types.READ_PAYMENT_OPTIONS_SUCCESS;
    constructor( public paymentOptionInfo: any ) { }
}

export class ReadPaymentOptionsError implements Action {
    readonly type = Types.READ_PAYMENT_OPTIONS_ERROR;
    constructor(public error: string) { }
}

export class CreateInvoice implements Action {
    readonly type = Types.CREATE_INVOICE;
    constructor(public request: createInvoiceRequest) { }
}

export class CreateInvoiceSuccess implements Action {
    readonly type = Types.CREATE_INVOICE_SUCCESS;
    constructor(public successInvoiceInfo: SuccessInvoiceInfo) {}
}

export class CreateInvoiceError implements Action {
    readonly type = Types.CREATE_INVOICE_ERROR;
    constructor(public error: string) { }
}

export class UploadPaymentReceipt implements Action {
  readonly type = Types.UPLOAD_PAYMENT_RECEIPT;
  constructor(public request: UploadPaymentReceiptRequest) {}
}

export class UploadPaymentReceiptSuccess implements Action {
  readonly type = Types.UPLOAD_PAYMENT_RECEIPT_SUCCESS;
  constructor(public url: string) {}
}

export class UploadPaymentReceiptError implements Action {
  readonly type = Types.UPLOAD_PAYMENT_RECEIPT_ERROR;
  constructor(public error: string) {}
}

export type All
    = ReadPaymentOptions
    | ReadPaymentOptionsSuccess
    | ReadPaymentOptionsError
    | CreateInvoice
    | CreateInvoiceSuccess
    | CreateInvoiceError
    | UploadPaymentReceipt
    | UploadPaymentReceiptSuccess
    | UploadPaymentReceiptError



