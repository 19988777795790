import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';

import * as fromActions from './inquiries.actions';

import { NotificationService } from '@app/services';
import { InquiriesService } from "@app/services/inquiries.service";

@Injectable ()

export class InquiriesEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    public inquiriesService: InquiriesService
  ) { }

  public read = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.CREATE_INQUIRIES),
          map((action: fromActions.CreateInquiries) => action.payload),
          mergeMap((payload) => {
                return from(this.inquiriesService.createInquiries(payload)).pipe(
                map(() => new fromActions.CreateInquiriesSuccess()),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.CreateInquiriesError(err.message))})
              );
            })
      )
  })
}
