import { Action } from "@ngrx/store";
import {GetQuotationsRequest, SearchQuotationsRequest} from './quotation-paging.models';
import { Quotation } from "@app/models/quotation.model";

export enum Types {

  GET_FIRST_QUOTATIONS = '[Quotation] Get First Quotation: Start',
  GET_FIRST_QUOTATIONS_SUCCESS = '[Quotation] Get First Quotation: Success',
  GET_FIRST_QUOTATIONS_ERROR = '[Quotation] Get First Quotation: Error',

  GET_PREVIOUS_QUOTATIONS = '[Quotation] Get Previous Quotation: Start',
  GET_PREVIOUS_QUOTATIONS_SUCCESS = '[Quotation] Get Previous Quotation: Success',
  GET_PREVIOUS_QUOTATIONS_ERROR = '[Quotation] Get Previous Quotation: Error',

  GET_NEXT_QUOTATIONS = '[Quotation] Get Next Quotation: Start',
  GET_NEXT_QUOTATIONS_SUCCESS = '[Quotation] Get Next Quotation: Success',
  GET_NEXT_QUOTATIONS_ERROR = '[Quotation] Get Next Quotation: Error',

  SEARCH_QUOTATIONS = '[Quotation] Search Quotation: Start',
  SEARCH_QUOTATIONS_SUCCESS = '[Quotation] Search Quotation: Success',
  SEARCH_QUOTATIONS_ERROR = '[Quotation] Search Quotation: Error',
}

export class GetFirstQuotations implements Action {
  readonly type = Types.GET_FIRST_QUOTATIONS;
  constructor(public request: GetQuotationsRequest) { }
}

export class GetFirstQuotationsSuccess implements Action {
  readonly type = Types.GET_FIRST_QUOTATIONS_SUCCESS;
  constructor(public total: number, public firstQuotation: any, public lastQuotation: any, public quotations: Quotation[]) { }
}

export class GetFirstQuotationsError implements Action {
  readonly type = Types.GET_FIRST_QUOTATIONS_ERROR;
  constructor(public error: string) { }
}

export class GetPreviousQuotations implements Action {
  readonly type = Types.GET_PREVIOUS_QUOTATIONS;
  constructor(public request: GetQuotationsRequest) { }
}

export class GetPreviousQuotationsSuccess implements Action {
  readonly type = Types.GET_PREVIOUS_QUOTATIONS_SUCCESS;
  constructor(public total: number, public firstQuotation: any, public lastQuotation: any, public quotations: Quotation[]) { }
}

export class GetPreviousQuotationsError implements Action {
  readonly type = Types.GET_PREVIOUS_QUOTATIONS_ERROR;
  constructor(public error: string) { }
}

export class GetNextQuotations implements Action {
  readonly type = Types.GET_NEXT_QUOTATIONS;
  constructor(public request: GetQuotationsRequest) { }
}

export class GetNextQuotationsSuccess implements Action {
  readonly type = Types.GET_NEXT_QUOTATIONS_SUCCESS;
  constructor(public total: number, public firstQuotation: any, public lastQuotation: any, public quotations: Quotation[]) { }
}

export class GetNextQuotationsError implements Action {
  readonly type = Types.GET_NEXT_QUOTATIONS_ERROR;
  constructor(public error: string) { }
}

export class SearchQuotations implements Action {
  readonly type = Types.SEARCH_QUOTATIONS;
  constructor(public request: SearchQuotationsRequest) { }
}

export class SearchQuotationsSuccess implements Action {
  readonly type = Types.SEARCH_QUOTATIONS_SUCCESS;
  constructor(public total: number, public firstQuotation: any, public lastQuotation: any, public quotations: Quotation[]) { }
}

export class SearchQuotationsError implements Action {
  readonly type = Types.SEARCH_QUOTATIONS_ERROR;
  constructor(public error: string) { }
}

export type All
  = GetFirstQuotations
  | GetFirstQuotationsSuccess
  | GetFirstQuotationsError
  | GetPreviousQuotations
  | GetPreviousQuotationsSuccess
  | GetPreviousQuotationsError
  | GetNextQuotations
  | GetNextQuotationsSuccess
  | GetNextQuotationsError
  | SearchQuotations
  | SearchQuotationsSuccess
  | SearchQuotationsError
