import { createSelector, createFeatureSelector } from '@ngrx/store';

import { PaymentState } from './payment.reducers';

export const getPaymentState = createFeatureSelector<PaymentState>('payment');

export const getLoading = createSelector(
    getPaymentState,
    (state) => state.getLoading
);

export const getPaymentOptions = createSelector(
    getPaymentState,
    (state) => state.paymentOptionInfo
);

export const getCreateInvoiceSuccess = createSelector(
    getPaymentState,
    (state) => state.successInvoiceInfo
)

export const getUploadPaymentReceiptSuccess = createSelector(
  getPaymentState,
  (state) => state.successPaymentReceiptInfo
)
