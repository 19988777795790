import { Action } from '@ngrx/store';

export enum Types {
  GET_MERCHANT = '[Merchant] Get merchant: Start',
  GET_MERCHANT_SUCCESS = '[Merchant] Get merchant: Success',
  GET_MERCHANT_ERROR = '[Merchant] Get merchant: Error',

  SET_MERCHANT_ID = '[Merchant] Set merchant id: Start',
  CLEAR_SET_MERCHANT_ID = '[Merchant] Clear et merchant id: Start',
}

export class GetMerchant implements Action {
  readonly type = Types.GET_MERCHANT;
  constructor(public merchantRef: string) { }
}

export class GetMerchantSuccess implements Action {
  readonly type = Types.GET_MERCHANT_SUCCESS;
  constructor( public merchant: any) { }
}

export class GetMerchantError implements Action {
  readonly type = Types.GET_MERCHANT_ERROR;
  constructor(public error: string) { }
}

export class SetMerchantId implements Action {
  readonly type = Types.SET_MERCHANT_ID;
  constructor( public merchantId: string) { }
}

export class ClearSetMerchantId implements Action {
  readonly type = Types.CLEAR_SET_MERCHANT_ID;
  constructor( public merchantId: string) { }
}

export type All
    = GetMerchant
    | GetMerchantSuccess
    | GetMerchantError
    | SetMerchantId
    | ClearSetMerchantId
