import {BaseThumbnailService} from "@app/services/thumbnail/base-thumbnail.service";

import {Injectable} from "@angular/core";
import {AngularFireStorage} from "@angular/fire/compat/storage";
import {Constants} from "@app/shared/utils/constant";

@Injectable({
    providedIn: 'root'
})
export class SingleThumbnailService extends BaseThumbnailService{
    constructor(public storage: AngularFireStorage) {
        super(storage, Constants.THUMBNAIL_SINGLE_PREF);
    }

    async uploadAndGenerateThumbnail(file: File, imageFolder: string): Promise<string>{
        const urls = await this.baseUploadAndGenerateThumbnails(file, imageFolder);
        return urls[0];
    }
}
