import * as fromActions from '@app/store/quotation';

export interface QuotationState {
  quotations: any[];
  selectedQuotation: any;
  loading: boolean;
  error: string;
  options: any;
  successOrderInfo: any;
  isSuccess: boolean;
  checkoutDetails: any;
  billsCount: number;
}

const initialState: QuotationState = {
  quotations: [],
  selectedQuotation: null,
  loading: false,
  error: null,
  options: null,
  successOrderInfo: null,
  isSuccess: false,
  checkoutDetails: null,
  billsCount: 0
}

export function reducer(state: QuotationState = initialState, action: fromActions.All){
  switch(action.type) {
    case fromActions.Types.GET_QUOTATIONS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_QUOTATIONS_SUCCESS: {
        return { ...state, loading: false, quotations: action.quotations, error: null };
    }
    case fromActions.Types.GET_QUOTATIONS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.GET_QUOTATION_DETAIL: {
      return {...state, loading: true, selectedQuotation: null, error: null}
    }
    case fromActions.Types.GET_QUOTATION_DETAIL_SUCCESS: {
      return {...state, loading: false, selectedQuotation: action.quotation, error: null}
    }
    case fromActions.Types.GET_QUOTATION_DETAIL_ERROR: {
      return {...state, loading: false, selectedQuotation: null, error: action.error}
    }

    case fromActions.Types.SUBMIT_QUOTATION_ORDER: {
      return { ...state, checkoutLoading: true, error: null, isSuccess: false };
    }
    case fromActions.Types.SUBMIT_QUOTATION_ORDER_SUCCESS: {
        return { ...state, checkoutLoading: false, successOrderInfo: action.successOrderInfo, error: null, isSuccess: true};
    }
    case fromActions.Types.SUBMIT_QUOTATION_ORDER_ERROR: {
        return { ...state, checkoutLoading: false, error: action.error, isSuccess: false};
    }

    case fromActions.Types.CLEAR_DATA: {
      return { ...state, successOrderInfo: null, billsCount: 0};
    }

    default: {
      return state;
    }
  }
}
