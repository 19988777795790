import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {from, of, withLatestFrom} from 'rxjs';
import { map, catchError, take, switchMap } from 'rxjs/operators';
import * as fromActions from './cart.actions';
import { CartService } from "@app/services/cart.service";
import {Store} from "@ngrx/store";
import * as fromRoot from '@app/store';
import * as fromCart from '@app/store/cart';

@Injectable ()

export class CartEffects {
  constructor (
    public cartService: CartService,
    private actions: Actions,
    private store: Store<fromRoot.State>,
  ){}

  public addToCart = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.ADD_TO_CART),
      map((action: fromActions.AddToCart) => action.payload),
      withLatestFrom(this.store.select(fromCart.getCart)),
      switchMap(([payload, cart]) => {
        take(1)
            return from(this.cartService.addToCart(payload.merchantId, cart, payload.cartItem)).pipe(
            map((cart) => new fromActions.AddToCartSuccess(cart)),
            catchError(err => {
              console.log('err: ',err);
              return of(new fromActions.AddToCartError(err.message))
            })
          );
        })
      )
  })

  public removeFromCart = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.REMOVE_FROM_CART),
      map((action: fromActions.RemoveFromCart) => action.payload),
        withLatestFrom(this.store.select(fromCart.getCart)),
        switchMap(([payload, cart]) => {
        take(1)
            return from(this.cartService.removeFromCart(payload.merchantId, cart, payload.cartItem)).pipe(
            map((cart) => new fromActions.RemoveFromCartSuccess(cart)),
            catchError(err => {
              console.log('err: ',err);
              return of(new fromActions.RemoveFromCartError(err.message))
            })
          );
        })
      )
  })

  public addItemQuantity = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.ADD_ITEM_QUANTITY),
      map((action: fromActions.AddItemQuantity) => action.payload),
        withLatestFrom(this.store.select(fromCart.getCart)),
        switchMap(([payload, cart]) => {
        take(1)
            return from(this.cartService.addItemQuantity(payload.merchantId, cart, payload.cartItem)).pipe(
            map((cart) => new fromActions.AddItemQuantitySuccess(cart)),
            catchError(err => {
              console.log('err: ',err);
              return of(new fromActions.AddItemQuantityError(err.message))
            })
          );
        })
      )
  })

  public subtractItemQuantity = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.SUBTRACT_ITEM_QUANTITY),
      map((action: fromActions.SubtractItemQuantity) => action.payload),
        withLatestFrom(this.store.select(fromCart.getCart)),
        switchMap(([payload, cart]) => {
        take(1)
            return from(this.cartService.subtractItemQuantity(payload.merchantId, cart, payload.cartItem)).pipe(
            map((cart) => new fromActions.SubtractItemQuantitySuccess(cart)),
            catchError(err => {
              console.log('err: ',err);
              return of(new fromActions.SubtractItemQuantityError(err.message))
            })
          );
        })
      )
  })

  public setCartToOrdered = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.SET_CART_TO_ORDERED),
      map((action: fromActions.SetCartToOrdered) => action.merchantId),
      withLatestFrom(this.store.select(fromCart.getCart)),
      switchMap(([merchantId, cart]) => {
        take(1)
        return from(this.cartService.setCartToOrderedData(cart?.id, merchantId)).pipe(
          map(() => new fromActions.SetCartToOrderedSuccess()),
          catchError(err => {
            console.log('err: ',err);
            return of(new fromActions.SetCartToOrderedError(err.message))
          })
        );
      })
    )
  })
}
