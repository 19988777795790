import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {QuotationOrder} from '@app/models/order.model';
import { DbPath } from '@app/shared/utils/db-path';
import { readOrderDetailRequest } from '@app/store/orders';
import { Observable, map, take } from 'rxjs';
import {Invoice} from "@app/models/payment/invoice";
import {Constants} from "@app/shared/utils/constant";
import {doc, docSnapshots, Firestore} from "@angular/fire/firestore";

@Injectable({
  providedIn: 'root'
})
export class OrdersService {

  constructor(
    private afs: AngularFirestore,
    private firestore: Firestore
  ) { }

  async createQuotationOrder(data: any): Promise<string> {
    return await this.afs
      .collection(DbPath.Merchants)
      .doc(data.merchantId)
      .collection(DbPath.Orders)
      .add(this.setQuotationCheckoutData(data)).then(docRef =>
      docRef.id
    );
  }

  setQuotationCheckoutData(data: any){
    const orderData: any = {
      createdAt: new Date(),
      customerInfo: data.customerInfo,
      merchantId: data.merchantId,
      status: data.status,
      total: data.total,
      type: data.type,
      paymentDetails: data.paymentInfo,
      orderItemsLength: data.items.length,
      number: data.number,
      orderItem: data.orderItem,
      schedule: data.schedule,
      paymentTerm: data.paymentTerm
    }
    return orderData;
  }

  setQuotationOrderItems(data: any, orderId: any) : any{
    this.insertQuotationOrderId(data.merchantId, orderId);
    data.items.forEach(order => {
      this.createQuotationOrderItem(order, orderId, data.merchantId);
    })
    return { id: orderId, ...data}
  }

  insertQuotationOrderId(merchantId, orderId){
    this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Orders).doc(orderId).set({orderId: orderId}, { merge: true });
  }

  async createQuotationOrderItem(orderItem: any, orderId: any, merchantId: string): Promise<string> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Orders).doc(orderId).collection(DbPath.OrderItems).add({...orderItem, merchantId: merchantId, orderId: orderId, createdAt: new Date()}).then(docRef =>
        docRef.id
    );
  }

  async createOrder(data: any): Promise<string> {
    return await this.afs.collection(DbPath.Merchants)
      .doc(data.merchantId)
      .collection(DbPath.Orders)
      .add(this.setProductCheckoutData(data))
      .then(docRef => docRef.id);
  }

  setProductCheckoutData(data){
    let products: any [] = [];
    data?.items.forEach(product => {
      products.push(product.name);
    })

    const orderData: any = {
      createdAt: new Date(),
      customerInfo: data.customerInfo,
      merchantId: data.merchantId,
      status: data.status,
      total: data.total,
      type: data.type,
      paymentDetails: data.paymentInfo,
      paymentInfo: data.paymentInfo,
      orderItemsLength: data.items.length,
      products: products,
      number: data.number,
    }
    return orderData;
  }

  setOrderItems(data: any, orderId: any) : any{
    this.insertOrderId(data.merchantId, orderId);
    data.items.forEach(order => {
      this.createOrderItem(order, orderId, data.merchantId);
    })
    return { id: orderId, ...data}
  }

  insertOrderId(merchantId, orderId){
    this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Orders).doc(orderId).set({orderId: orderId}, { merge: true });
  }

  async createOrderItem(orderItem: any, orderId: any, merchantId: string): Promise<string> {
    return await this.afs.collection(DbPath.Merchants).doc(merchantId).collection(DbPath.Orders).doc(orderId).collection(DbPath.OrderItems).add({...orderItem, merchantId: merchantId, orderId: orderId, createdAt: new Date()}).then(docRef =>
        docRef.id
    );
  }

  getMyOrders(data: any): Observable<any[]>{
    return this.afs.collectionGroup<any>(DbPath.Orders, ref => ref
      .where('customerInfo.customerId','==', data.customerId)
      .where('merchantId','==',data.merchantId)
      .where('type','==','product')
      .where('status','!=', Constants.DELETED)
      .orderBy('number', 'desc')
      ).get().pipe(
        take(1),
        map(snapshot => {
          return snapshot.docs.map(doc => {
            return ({id: doc.id, ...doc.data()});
          })
        })
      )
  }

  getOrderItems(orderId: string): Observable<any[]>{
    return this.afs.collectionGroup<any>(DbPath.OrderItems, ref => ref
      .where('orderId','==', orderId)).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({id: c.payload.doc.id, ...c.payload.doc.data(), })
        ))
    )
  }

  getOrderById(orderId){
    return this.afs.collectionGroup<any>(DbPath.Orders, ref => ref
      .where('orderId','==', orderId)
      ).snapshotChanges().pipe(
      map(changes =>
        changes.map(c =>
          ({id: c.payload.doc.id, ...c.payload.doc.data()})
      ))
    )
  }

  getQuotationOrderById(params: readOrderDetailRequest) {
    const docRef = doc(this.firestore, `${DbPath.Merchants}/${params.merchantId}/${DbPath.Orders}/${params.orderId}`);
    return docSnapshots(docRef).pipe(
      map(order => {
        return ({id: order.id, ...order.data()});
      })
    );
  }

  getQuotationOrderItems(orderId: string): Observable<QuotationOrder[]>{
    return this.afs.collectionGroup<any>(DbPath.OrderItems, ref => ref
      .where('orderId','==', orderId)
      ).get().pipe(
        map(snapshot => {
          return snapshot.docs.map(doc => {
            return ({id: doc.id, ...doc.data()});
          });

        })
      )
  }

  getOrderInvoices(params: readOrderDetailRequest): Observable<Invoice[]> {
    return this.afs.collection(`${DbPath.Merchants}/${params.merchantId}/${DbPath.Orders}/${params.orderId}/${DbPath.Invoices}`)
      .snapshotChanges().pipe(
      map(changes =>
        changes.map(c => {
          const data = c.payload.doc.data() as Invoice;
          if (data) {
            data.paidAt = data.paidAt.toDate();
            return { id: c.payload.doc.id, ...data as Invoice };
          }
          return null;
        }).filter(item => item !== null) // Filter out null values if needed
      )
    );
  }

  getMyQuotations(data: any): Observable<any[]>{
    return this.afs.collectionGroup<any>(DbPath.Orders, ref => ref
      .where('merchantId', '==', data.merchantId)
      .where('customerInfo.customerId','==', data.customerId)
      .where('type', '==', 'quotation')
      .where('status','!=', Constants.DELETED)
      .orderBy('number', 'desc')
      ).get().pipe(
        map(snapshot => {
          return snapshot.docs.map(doc => {
            return ({id: doc.id, ...doc.data()});
          });
        })
      )
  }

  updateOrder(data: any) {
    return this.afs.collection(DbPath.Merchants).doc(data.merchantId).collection(DbPath.Orders).doc(data.orderId).update(data.orderData);
  }

  async deleteOrder(data: any){
    await this.afs.collection(DbPath.Merchants).doc(data.merchantId)
        .collection(DbPath.Orders).doc(data.orderId)
        .update({
          'deletedAt': new Date(),
          'status': Constants.DELETED
        });
  }

}
