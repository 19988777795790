import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';
import {from, of, withLatestFrom} from 'rxjs';
import { map, switchMap, catchError, take } from 'rxjs/operators';

import { NotificationService } from '@app/services';
import { Store } from "@ngrx/store";
import * as fromActions from './quotation-paging.actions';
import * as fromQuotationPaging from '@app/store/quotation-paging';
import {QuotationsRepository} from "@app/repository/quotations.repository";

@Injectable ()

export class QuotationPagingEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    public quotationRepository: QuotationsRepository,
    public store: Store,
  ) { }

  public readFirstQuotations = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_FIRST_QUOTATIONS),
      map((action: fromActions.GetFirstQuotations) => action.request),
      withLatestFrom(this.store.select(fromQuotationPaging.getFirstQuotation), this.store.select(fromQuotationPaging.getPagingState)),
      switchMap(([request, firstQuotation, pagingState]) => {
            take(1)
            return from(this.quotationRepository.getFirstQuotations(request, firstQuotation, pagingState.searchText)).pipe(
            map((response) => new fromActions.GetFirstQuotationsSuccess(
                response.total,
                response.firstQuotation,
                response.lastQuotation,
                response.quotations
            )),
            catchError(err => {
              this.notification.error(err.message);
              return of(new fromActions.GetFirstQuotationsError(err.message))})
          );
        })
      )
  })

  public readPreviousQuotations = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_PREVIOUS_QUOTATIONS),
      map((action: fromActions.GetPreviousQuotations) => action.request),
        withLatestFrom(this.store.select(fromQuotationPaging.getFirstQuotation), this.store.select(fromQuotationPaging.getPagingState)),
        switchMap(([request, firstQuotation, pagingState]) => {
            take(1)
            return from(this.quotationRepository.getPreviousQuotations(request, firstQuotation, pagingState.searchText)).pipe(
            map((response) => new fromActions.GetPreviousQuotationsSuccess(
                response.total,
                response.firstQuotation,
                response.lastQuotation,
                response.quotations)),
            catchError(err => {
              this.notification.error(err.message);
              return of(new fromActions.GetPreviousQuotationsError(err.message))})
          );
        })
      )
  })

  public readNextQuotations = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.GET_NEXT_QUOTATIONS),
      map((action: fromActions.GetNextQuotations) => action.request),
        withLatestFrom(this.store.select(fromQuotationPaging.getLastQuotation), this.store.select(fromQuotationPaging.getPagingState)),
        switchMap(([request, lastQuotation, pagingState]) => {
            take(1)
            return from(this.quotationRepository.getNextQuotations(request, lastQuotation, pagingState.searchText)).pipe(
            map((response) => new fromActions.GetNextQuotationsSuccess(
                response.total,
                response.firstQuotation,
                response.lastQuotation,
                response.quotations)),
            catchError(err => {
              this.notification.error(err.message);
              return of(new fromActions.GetNextQuotationsError(err.message))})
          );
        })
      )
  })

  public searchQuotations = createEffect(() => {
    return this.actions.pipe(
        ofType(fromActions.Types.SEARCH_QUOTATIONS),
        map((action: fromActions.SearchQuotations) => action.request),
        switchMap((request) => {
            take(1)
            return from(this.quotationRepository.searchQuotations(request)).pipe(
                map((response) => new fromActions.SearchQuotationsSuccess(
                    response.total,
                    response.firstQuotation,
                    response.lastQuotation,
                    response.quotations)),
                catchError(err => {
                    this.notification.error(err.message);
                    return of(new fromActions.SearchQuotationsError(err.message))})
            );
        })
    )
  })

}
