import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { DbPath } from '@app/shared/utils/db-path';
import { createInvoiceRequest } from '@app/store/payment/payment.model';

import { Observable, map } from 'rxjs';
import { Constants } from "@app/shared/utils/constant";
import { ExtendedSchedule } from "@app/models/payment/schedule";
import {SingleThumbnailService} from "@app/services/thumbnail/single-thumbnail.service";

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(
    private afs: AngularFirestore,
    private singleThumbnailService: SingleThumbnailService
  ) { }

  readPaymentInfo(merchantId: any): Observable<any> {
    return this.afs.collection<any>(DbPath.Merchants).doc(merchantId).collection(DbPath.PaymentOptions, ref => ref
      .where('enabled', '==', true)).snapshotChanges().pipe(
        map(paymentOption => {
            return paymentOption.map(paymentOption => {
                return ({
                      id: paymentOption.payload.doc.id, ...paymentOption.payload.doc.data()
                });
            });
        }))
  }

  async createInvoice(request: createInvoiceRequest): Promise<string> {
    return await this.afs.collection(DbPath.Merchants)
      .doc(request.merchantId)
      .collection(DbPath.Orders)
      .doc(request.orderId)
      .collection(DbPath.Invoices)
      .add(request.invoiceData)
      .then(docRef => {
      return docRef.id;
    });
  }

    computeToPayBySchedule(schedules: ExtendedSchedule[]): number {
        if(!schedules.some(schedule => [Constants.BILLING_STATUS.IN_BILLING, Constants.BILLING_STATUS.OVERDUE].includes(schedule.status))) {
            const nextIndex = schedules.findIndex(schedule => {
                return [Constants.BILLING_STATUS.PAID, Constants.BILLING_STATUS.VERIFYING].includes(schedule.status);
            }) + 1;

            if (nextIndex >= 0 && nextIndex < schedules.length) {
                return schedules[nextIndex].amount;
            } else {
                // Handle the case where the next index is out of bounds or not found
                return 0; // or some other default value as appropriate
            }
        }

        return schedules.reduce((sum, schedule) => {
            if (schedule.status === Constants.SERVICE_ORDER_STATUS.IN_BILLING ||
                schedule.status === Constants.BILLING_STATUS.OVERDUE) {
                return sum + schedule.amount;
            }
            return sum;
        }, 0);
    }


  async uploadPaymentReceiptScreenshot(file: File): Promise<string>{
    if(!file) {
      return null;
    }

    return await this.singleThumbnailService.uploadAndGenerateThumbnail(file, 'payment-receipt-screenshot');
  }
}
