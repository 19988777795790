export class DbPath {
    public static readonly Users = 'users';
    public static readonly Products = 'products';
    public static readonly Quotations = 'quotations';
    public static readonly Merchants = 'merchants';
    public static readonly Orders = 'orders';
    public static readonly OptionGroups = 'optionGroups';
    public static readonly Options = 'options';
    public static readonly OrderItems = 'orderItems';
    public static readonly Inquiries = 'inquiries';
    public static readonly PaymentOptions = 'paymentOptions';
    public static readonly Carts = 'carts';
    public static readonly CartItems = 'cartItems';
    public static readonly Billings = 'billings';
    public static readonly Invoices = 'invoices';
    public static readonly Posts = 'posts';
    public static readonly Elements = 'elements';

}
