import { Injectable } from '@angular/core';
import {
  collection, doc, docData,
  endBefore, Firestore,
  getCountFromServer, getDocs,
  limit, limitToLast,
  orderBy,
  query,
  startAfter, startAt,
  where
} from "@angular/fire/firestore";
import {DbPath} from "@app/shared/utils/db-path";
import {DocumentData, Query} from "rxfire/firestore/interfaces";
import {GetQuotationsRequest, SearchQuotationsRequest} from "@app/store/quotation-paging";
import {firstValueFrom} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class QuotationsRepository {

  constructor(private firestore: Firestore) { }

  getQuotationsBaseQuery(merchantId: string, trigram: string) {
    const quotationCollection =
        collection(this.firestore,
            `${DbPath.Merchants}/${merchantId}/${DbPath.Quotations}`);

    let quotationsBaseQuery = query(quotationCollection,
        where('showOnPage', '==', true),
        orderBy('title', 'asc'),
    );
    if(trigram.length >= 3) {
      quotationsBaseQuery = query(quotationsBaseQuery,
          where('trigram', 'array-contains-any', trigram.toLowerCase().split(' ')));
    }
    return quotationsBaseQuery;
  }

  async getFirstQuotations(request: GetQuotationsRequest, firstQuotation: any, searchText: string): Promise<any> {
    const quotationsBaseQuery = this.getQuotationsBaseQuery(request.merchantId, searchText);
    const total = (await getCountFromServer(quotationsBaseQuery)).data().count;

    let quotationsQuery = query(quotationsBaseQuery, limit(request.limit));
    if(firstQuotation){
      quotationsQuery = query(quotationsQuery,
          startAt(firstQuotation));
    }
    let quotationsResult = await this.queryQuotations(quotationsQuery);

    return {
      total,
      ...quotationsResult
    }
  }

  async getNextQuotations(request: GetQuotationsRequest, lastQuotation: any, searchText: string): Promise<any> {
    let quotationsBaseQuery = this.getQuotationsBaseQuery(request.merchantId, searchText)
    const total = (await getCountFromServer(quotationsBaseQuery)).data().count;

    let quotationsQuery = query(quotationsBaseQuery,
        startAfter(lastQuotation),
        limit(request.limit));

    let quotationsResult = await this.queryQuotations(quotationsQuery);
    return {
      total,
      ...quotationsResult
    }
  }

  async getPreviousQuotations(request: GetQuotationsRequest, firstQuotation: any, searchText: string): Promise<any> {
    let quotationsBaseQuery = this.getQuotationsBaseQuery(request.merchantId, searchText)
    const total = (await getCountFromServer(quotationsBaseQuery)).data().count;

    let quotationsQuery = query(quotationsBaseQuery,
        endBefore(firstQuotation),
        limitToLast(request.limit));

    let quotationsResult = await this.queryQuotations(quotationsQuery);
    return {
      total,
      ...quotationsResult
    }
  }

  async searchQuotations(request: SearchQuotationsRequest): Promise<any> {
    let quotationsBaseQuery = this.getQuotationsBaseQuery(request.merchantId, request.searchText)
    const total = (await getCountFromServer(quotationsBaseQuery)).data().count;

    let quotationsQuery = query(quotationsBaseQuery, limit(request.limit));

    let quotationsResult = await this.queryQuotations(quotationsQuery);
    return {
      total,
      ...quotationsResult
    }
  }

  async queryQuotations(quotationsQuery: Query<DocumentData>) {
    const quotationSnapshots = await getDocs(quotationsQuery);

    const nextFirstQuotation = Object.freeze(quotationSnapshots.docs[0]);
    const nextLastQuotation = Object.freeze(quotationSnapshots.docs[quotationSnapshots.docs.length - 1]);
    const quotations = quotationSnapshots.docs
        .map((doc) =>
            ({id: doc.id, ...doc.data()})
        );
    return {
      firstQuotation: nextFirstQuotation,
      lastQuotation: nextLastQuotation,
      quotations
    };
  }

  async getQuotationDetail(merchantId: string, quotationRef: string): Promise<any> {
    const quotationCollection =
      collection(this.firestore,
        `${DbPath.Merchants}/${merchantId}/${DbPath.Quotations}`);

    let q = query(quotationCollection,
      where('permalink', '==', quotationRef),
      limit(1)
    );
    let querySnapshot = await getDocs(q);
    if (querySnapshot.docs.length > 0) {
      let doc = querySnapshot.docs.at(0);
      return {id: doc.id, ...doc.data()};
    }
    const ref = doc(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Quotations}/${quotationRef}`);
    let data = await firstValueFrom(docData(ref));
    return {id: quotationRef, ...data};
  }
}
