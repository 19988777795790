import { Action } from '@ngrx/store';

export enum Types {
    SET_PAYMENT_DETAILS = '[Set Payment Details] Payment details: start',
    UPDATE_PAYMENT_STATUS = '[Update Payment Status] Payment status: start',
    UPDATE_PAYMENT_STATUS_SUCCESS = '[Update Payment Status Success] Payment status: Success',
    UPDATE_PAYMENT_STATUS_ERROR = '[Update Payment Status Error] Payment status: Error',
}


export class SetPaymentDetails implements Action {
    readonly type = Types.SET_PAYMENT_DETAILS;
    constructor(public details: any) { }
}

export class UpdatePaymentStatus implements Action {
    readonly type = Types.UPDATE_PAYMENT_STATUS;
    constructor(public details: any) { }
}

export class UpdatePaymentStatusSuccess implements Action {
    readonly type = Types.UPDATE_PAYMENT_STATUS_SUCCESS;
    constructor() { }
}

export class UpdatePaymentStatusError implements Action {
    readonly type = Types.UPDATE_PAYMENT_STATUS_ERROR;
    constructor(public error: string) { }
}

export type All =
    | SetPaymentDetails
    | UpdatePaymentStatus
    | UpdatePaymentStatusSuccess
    | UpdatePaymentStatusError
