import { Injectable } from "@angular/core";
import { createEffect, Actions, ofType } from '@ngrx/effects';

import { of } from 'rxjs';
import { map, catchError, mergeMap } from 'rxjs/operators';


import * as fromActions from './merchant.actions';

import { NotificationService } from '@app/services';
import { MerchantService } from "@app/services/merchant.service";

@Injectable ()

export class MerchantEffects {
  constructor (
    private actions: Actions,
    private notification: NotificationService,
    public merchantService: MerchantService
  ) { }

  public read = createEffect(() => {
    return this.actions.pipe(
          ofType(fromActions.Types.GET_MERCHANT),
          map((action: fromActions.GetMerchant) => action.merchantRef),
          mergeMap((payload) => {
                return this.merchantService.getMerchant(payload).pipe(
                map((merchant) => new fromActions.GetMerchantSuccess(merchant)),
                catchError(err => {
                  this.notification.error(err.message);
                  return of(new fromActions.GetMerchantError(err.message))})
              );
            })
      )
  })
}
