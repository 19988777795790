import { createFeatureSelector, createSelector } from "@ngrx/store";
import { MerchantState } from "./merchant.reducers.js";

export const getMerchantState = createFeatureSelector<MerchantState>('merchant');
export const getMerchant = createSelector(
  getMerchantState,
  (state) => state.merchant
)

export const getMerchantId = createSelector(
  getMerchantState,
  (state) => state.merchantId
)
