import {Injectable} from "@angular/core";
import {AngularFireStorage, AngularFireUploadTask} from "@angular/fire/compat/storage";
import {combineLatest, Observable, of, takeWhile, timer} from "rxjs";
import {catchError, map, switchMap} from "rxjs/operators";


@Injectable({
    providedIn: 'root'
})
export abstract class BaseThumbnailService {
    task: AngularFireUploadTask;

    protected constructor(public storage: AngularFireStorage,
                          private thumbnailPrefix: string[]) {
    }
    /**
     * Uploads an image file to Firebase Storage and polls for the creation of thumbnails.
     *
     * This method will first upload the provided image file to Firebase Storage under the imageFolder directory
     * with a unique timestamp-based file name. After the upload, it will begin polling for the availability of
     * thumbnails by calling the `pollForThumbnails` method of the `thumbnail` service.
     *
     * @param {File} file The image file to be uploaded.
     * @param imageFolder The image folder for which the image will be uploaded
     * @returns {Promise<string>} A promise that resolves with the URL of the uploaded image. If thumbnails are
     *                            successfully generated within the polling period, it returns the URL of the large
     *                            thumbnail; otherwise, it returns the URL of the original image.
     */
    protected async baseUploadAndGenerateThumbnails(file: File, imageFolder: string): Promise<string[]> {
        if (file) {
            const path = `${imageFolder}/${Date.now()}_${file.name}`;
            this.storage.ref(path);
            this.task = this.storage.upload(path, file);

            return (await this.task).ref.getDownloadURL().then(async url => {
                let urls: string[] = [url];
                if (url) {
                    const thumbnails = await this.pollForThumbnails(path, imageFolder);
                    if (thumbnails.length > 0) {
                        urls = thumbnails
                        await this.storage.storage.refFromURL(url).delete();
                    }
                }
                return urls;
            });
        } else {
            return null
        }
    }

    /**
     * Polls for the existence of generated thumbnails in Firebase Storage.
     *
     * This method will check for the generated thumbnails at a specified interval.
     * If the thumbnails are found or the maximum number of polling attempts is reached,
     * the polling will stop.
     *
     * @param {string} imagePath The base image path of the uploaded image.
     * @param {string} imageFolder The folder within Firebase Storage where the image is stored.
     * @returns {string} The URL of the large thumbnail if it is found, otherwise returns the original URL.
     */
    private async pollForThumbnails(imagePath: string, imageFolder: string): Promise<string[]> {
        const pollIntervalMs = 5000; // Poll every 5 seconds
        const maxPollCount = 96; // Maximum number of polls (8 minutes total)
        let pollCount = 0;

        // Promise that resolves with an array of thumbnail URLs or an empty array
        return new Promise<string[]>(resolve => {
            const pollForThumbnails$ = timer(0, pollIntervalMs).pipe(
                switchMap(() => this.checkThumbnailsReady(imagePath, imageFolder)),
                takeWhile(urls => urls.length < this.thumbnailPrefix.length && pollCount++ < maxPollCount, true) // Complete the stream when we get URLs or reach max polls
            );

            const subscription = pollForThumbnails$.subscribe({
                next: (urls) => {
                    if (urls.length === this.thumbnailPrefix.length) {
                        subscription.unsubscribe();
                        resolve(urls); // Resolve the promise with the array of thumbnail URLs
                    }
                },
                error: (err) => {
                    console.error(`An error occurred while trying to retrieve the thumbnails: ${err}`);
                    subscription.unsubscribe();
                    resolve([]); // Resolve the promise with an empty array on error
                },
                complete: () => {
                    if (!subscription.closed) {
                        console.log("Unable to retrieve the thumbnails in a given period.");
                        resolve([]); // Resolve the promise with an empty array if polling completes without URLs
                    }
                }
            });
        });
    }


    private checkThumbnailsReady(imagePath: string, imageFolder: string): Observable<string[]> {
        const thumbnailImagePaths = this.getThumbnailImagePaths(imagePath, imageFolder);

        // Create an observable for each thumbnail to check if it's ready
        const checks$ = thumbnailImagePaths.map(path =>
            this.storage.ref(path).getDownloadURL().pipe(
                catchError(() => of('')), // Return an empty string in case of error
            )
        );

        // Combine all thumbnail observables and filter out any that failed to load
        return combineLatest(checks$).pipe(
            map(urls => urls.filter(url => url !== ''))
        );
    }

    private replaceByThumbnailImagePath(imageFolder: string, imagePath: string, prefix: string){
        const fileName = imagePath.split('/').pop(); // Get 'example.jpg'
        if (!fileName) {
            throw new Error('Invalid image path provided.');
        }
        const newFileName = `${prefix}${fileName}`; // Create 'opt_large_example.jpg'
        return `${imageFolder}/${newFileName}`;
    }
    private getThumbnailImagePaths = (imagePath: string, imageFolder: string) =>
        this.thumbnailPrefix.map(prefix => this.replaceByThumbnailImagePath(imageFolder, imagePath, prefix));
}