import * as fromActions from '@app/store/merchant';
export interface MerchantState {
  merchant: any;
  loading: boolean;
  error: string;
  merchantId: string;
}

const initialState: MerchantState = {
  merchant: null,
  loading: false,
  error: null,
  merchantId: null,
}

export function reducer(state: MerchantState = initialState, action: fromActions.All){
  switch(action.type) {
    case fromActions.Types.GET_MERCHANT: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_MERCHANT_SUCCESS: {
        return { ...state, loading: false, merchant: action.merchant, error: null };
    }
    case fromActions.Types.GET_MERCHANT_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.SET_MERCHANT_ID: {
      return { ...state, loading: false, merchantId: action.merchantId};
    }

    case fromActions.Types. CLEAR_SET_MERCHANT_ID: {
      return { ...state, loading: false, merchantId: null};
    }

    default: {
      return state;
    }
  }
}
