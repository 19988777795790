import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as PostActions from './posts-paging.actions';
import {catchError, from, map, of, switchMap, withLatestFrom} from "rxjs";
import { Injectable } from "@angular/core";
import {PostsRepository} from "@app/repository/posts.repository";
import * as fromPostPaging from '@app/store/posts-paging';
import {Store} from "@ngrx/store";

@Injectable()
export class PostPagingEffects {
    constructor(
        private action$: Actions,
        private postsRepository: PostsRepository,
        public store: Store,
    ) {}

    getFirstPosts$ = createEffect(() => this.action$.pipe(
      ofType(PostActions.getFirstPosts),
      withLatestFrom(this.store.select(fromPostPaging.getFirstPost), this.store.select(fromPostPaging.getPagingState)),
      switchMap(([request, firstPost, pagingState]) =>
          from(this.postsRepository.getFirstPosts(request, firstPost, pagingState.searchText)).pipe(
              map(response => PostActions.getFirstPostsSuccess({
                  total: response.total,
                  firstPost: response.firstPost,
                  lastPost: response.lastPost,
                  postsByPage: response.posts})),
              catchError(error => of(PostActions.getFirstPostsFailure({ error: error.message })))
          )
      )
    ))

    getNextPosts$ = createEffect(() => this.action$.pipe(
      ofType(PostActions.getNextPosts),
        withLatestFrom(this.store.select(fromPostPaging.getLastPost), this.store.select(fromPostPaging.getPagingState)),
        switchMap(([request, lastPost, pagingState]) =>
          from(this.postsRepository.getNextPosts(request, lastPost, pagingState.searchText)).pipe(
              map(response => PostActions.getNextPostsSuccess({
                  total: response.total,
                  firstPost: response.firstPost,
                  lastPost: response.lastPost,
                  postsByPage: response.posts})),
              catchError(error => of(PostActions.getNextPostsFailure({ error })))
          )
      )
    ))

    getPreviousPosts$ = createEffect(() => this.action$.pipe(
      ofType(PostActions.getPreviousPosts),
        withLatestFrom(this.store.select(fromPostPaging.getFirstPost), this.store.select(fromPostPaging.getPagingState)),
        switchMap(([request, firstPost, pagingState]) =>
          from(this.postsRepository.getPreviousPosts(request, firstPost, pagingState.searchText)).pipe(
              map(response => PostActions.getPreviousPostsSuccess({
                  total: response.total,
                  firstPost: response.firstPost,
                  lastPost: response.lastPost,
                  postsByPage: response.posts})),
              catchError(error => of(PostActions.getPreviousPostsFailure({ error })))
          )
      )
    ))

    searchPosts = createEffect(() => this.action$.pipe(
        ofType(PostActions.searchPosts),
        switchMap((request) =>
            from(this.postsRepository.searchPosts(request)).pipe(
                map(response => PostActions.searchPostsSuccess({
                    total: response.total,
                    firstPost: response.firstPost,
                    lastPost: response.lastPost,
                    postsByPage: response.posts})),
                catchError(error => of(PostActions.searchPostsFailure({ error })))
            )
        )
    ))
}
