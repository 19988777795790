import { createFeatureSelector, createSelector } from "@ngrx/store";
import { ProductPagingState } from "./products-paging.reducers";

export const getProductState = createFeatureSelector<ProductPagingState>('productPaging');

export const getProductsByPage = createSelector(
  getProductState,
  (state) => state.productsByPage
)

export const getLoading = createSelector(
  getProductState,
  (state) => state.loading
);

export const getFirstProduct = createSelector(
    getProductState,
    (state) => state.firstProduct
)

export const getLastProduct = createSelector(
    getProductState,
    (state) => state.lastProduct
)

export const getPagingState = createSelector(
    getProductState,
    (state) =>
      ({
        total: state.total,
        page: state.page,
        searchText: state.searchText
      })
)

