import { Action } from '@ngrx/store';
import { createInquiryRequest } from './inquiries.models';

export enum Types {
  CREATE_INQUIRIES = '[Inquiries] Create inquiries: Start',
  CREATE_INQUIRIES_SUCCESS = '[Inquiries] Create inquiries: Success',
  CREATE_INQUIRIES_ERROR = '[Inquiries] Create inquiries: Error',

  SET_INQUIRIES_FALSE_IS_SUCCESS = '[Inquiries] Set false inquiries is success: start',

}

export class CreateInquiries implements Action {
  readonly type = Types.CREATE_INQUIRIES;
  constructor(public payload: createInquiryRequest) { }
}

export class CreateInquiriesSuccess implements Action {
  readonly type = Types.CREATE_INQUIRIES_SUCCESS;
  constructor() { }
}

export class CreateInquiriesError implements Action {
  readonly type = Types.CREATE_INQUIRIES_ERROR;
  constructor(public error: string) { }
}

export class SetFalseIsSuccess implements Action {
  readonly type = Types.SET_INQUIRIES_FALSE_IS_SUCCESS;
  constructor() { }
}

export type All
    = CreateInquiries
    | CreateInquiriesSuccess
    | CreateInquiriesError
    | SetFalseIsSuccess
