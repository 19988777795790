import { Product } from '@app/models/product.model';
import * as fromActions from '@app/store/product';
export interface ProductState {
  products: any[];
  loading: boolean;
  error: string;
  options: any;
  order: any;
  successOrderInfo: any;
  isSuccess: boolean;
  checkoutDetails: any;
  checkoutLoading: boolean;
}

const initialState: ProductState = {
  products: [],
  loading: false,
  error: null,
  options: null,
  order: null,
  successOrderInfo: null,
  isSuccess: false,
  checkoutDetails: null,
  checkoutLoading: false,
}

export function reducer(state: ProductState = initialState, action: fromActions.All){
  switch(action.type) {
    case fromActions.Types.GET_PRODUCTS: {
      return { ...state, loading: true, error: null };
    }
    case fromActions.Types.GET_PRODUCTS_SUCCESS: {
        return { ...state, loading: false, products: action.products, error: null };
    }
    case fromActions.Types.GET_PRODUCTS_ERROR: {
        return { ...state, loading: false, error: action.error };
    }

    case fromActions.Types.SEARCH_PRODUCT: {
      return {...state, loading: true, error: null}
    }
    case fromActions.Types.SEARCH_PRODUCT_SUCCESS: {
      return {...state, loading: false, error: null, products: action.products}
    }
    case fromActions.Types.SEARCH_PRODUCT_ERROR: {
      return {...state, loading: false, error: action.error}
    }

    case fromActions.Types.SET_OPTIONS: {
      return { ...state, loading: false, options: action.options };
    }

    case fromActions.Types.CLEAR_SET_OPTIONS: {
      return { ...state, loading: false, options: null };
    }

    case fromActions.Types.SUBMIT_PRODUCT_ORDER: {
      return { ...state, checkoutLoading: true, error: null, isSuccess: false };
    }
    case fromActions.Types.SUBMIT_PRODUCT_ORDER_SUCCESS: {
        return { ...state, checkoutLoading: false, successOrderInfo: action.successOrderInfo, error: null, isSuccess: true};
    }
    case fromActions.Types.SUBMIT_PRODUCT_ORDER_ERROR: {
        return { ...state, checkoutLoading: false, error: action.error, isSuccess: false};
    }

    case fromActions.Types.CHECK_OUT_ORDER_PAY: {
      return { ...state, checkoutLoading: true, error: null };
    }

    case fromActions.Types.CHECK_OUT_ORDER_SUCCESS: {
      return { ...state, checkoutLoading: false, error: null, checkoutDetails: action.checkoutDetails};
    }

    case fromActions.Types.CHECK_OUT_ORDER_ERROR: {
        return { ...state, checkoutLoading: false, error: action.error, isSuccess: false };
    }

    case fromActions.Types.CLEAR_DATA: {
      return { ...state, successOrderInfo: null};
    }

    case fromActions.Types.RESET_DATA: {
      return {...initialState}
    }

    default: {
      return state;
    }
  }
}
