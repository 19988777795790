import { createFeatureSelector, createSelector } from "@ngrx/store";
import { OrderState } from "./order.reducers";

export const getOrderState = createFeatureSelector<OrderState>('orders');

export const getMyOrders = createSelector(
  getOrderState,
  (state) => state.myOrders
)

export const getOrdersItems = createSelector(
  getOrderState,
  (state) => state.orderitems
)

export const getOrdersById = createSelector(
  getOrderState,
  (state) => state.ordersById
)

export const getQuotations = createSelector(
  getOrderState,
  (state) => state.quotations
)

export const getQuotationOrderItems = createSelector(
  getOrderState,
  (state) => state.quotationOrderItems
)

export const getUpdateSuccess = createSelector(
  getOrderState,
  (state) => state.updateSuccess
)

export const getUpdateScheduleSuccess = createSelector(
    getOrderState,
    (state) => state.updateScheduleSuccess
)

export const getDeleteSuccess = createSelector(
  getOrderState,
  (state) => state.deleteSuccess
)

export const getProductOrderById = createSelector(
    getOrderState,
    (state) => state.productOrder
)

export const getQuotationOrderById = createSelector(
  getOrderState,
  (state) => state.quotationOrdersById
)

export const getQuotationOrder= createSelector(
    getOrderState,
    (state) => state.quotationOrder
)

export const getOrderInvoices = createSelector(
  getOrderState,
  (state) => state.invoices
);
