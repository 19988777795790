import { createFeatureSelector, createSelector } from "@ngrx/store";
import { QuotationPagingState } from "./quotation-paging.reducers";

export const getQuotationState = createFeatureSelector<QuotationPagingState>('quotationPaging');

export const getQuotationByPage = createSelector(
  getQuotationState,
  (state) => state.quotationsByPage
)

export const getLoading = createSelector(
  getQuotationState,
  (state) => state.loading
);

export const getFirstQuotation = createSelector(
    getQuotationState,
    (state) => state.firstQuotation
)

export const getLastQuotation = createSelector(
    getQuotationState,
    (state) => state.lastQuotation
)

export const getPagingState = createSelector(
    getQuotationState,
    (state) =>
        ({
            total: state.total,
            page: state.page,
            searchText: state.searchText
        })
)
