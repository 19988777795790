import { Injectable } from "@angular/core";
import { AngularFireUploadTask } from "@angular/fire/compat/storage";
import { NotificationService } from "@app/services";
import { Actions } from "@ngrx/effects";

import { createEffect, ofType } from '@ngrx/effects';
import { map, catchError, mergeMap, switchMap} from 'rxjs/operators';
import { from, of } from 'rxjs';
import * as fromActions from './payment.actions';
import { PaymentService } from "@app/services/payment.service";
@Injectable()

export class PaymentEffects {
    task: AngularFireUploadTask;
  constructor(
          private actions: Actions,
          private notification: NotificationService,
          private paymentService: PaymentService
  ) { }

  public readPaymentInfo = createEffect(() => {
      return this.actions.pipe(
            ofType(fromActions.Types.READ_PAYMENT_OPTIONS),
            map((action: fromActions.ReadPaymentOptions) => action.merchantId),
            mergeMap((merchantId) => {
                return from(this.paymentService.readPaymentInfo(merchantId)).pipe(
                  map((paymentInfo) => new fromActions.ReadPaymentOptionsSuccess(paymentInfo)),
                  catchError(err => {
                    this.notification.error(err.message);
                    return of(new fromActions.ReadPaymentOptionsError(err.message))})
                );
              })
      )
  })

  public createInvoice = createEffect(() => {
      return this.actions.pipe(
          ofType(fromActions.Types.CREATE_INVOICE),
          map((action: fromActions.CreateInvoice) => action.request),
          switchMap(request => {
              return from(this.paymentService.createInvoice(request)).pipe(
                  map(invoiceId => new fromActions.CreateInvoiceSuccess({success: true, invoiceId: invoiceId})),
                  catchError(err => {
                      this.notification.error(err.message);
                      return of(new fromActions.CreateInvoiceError(err.message));
                  })
              );
          })
      )
  })

  public uploadPaymentReceiptScreenshot = createEffect(() => {
    return this.actions.pipe(
      ofType(fromActions.Types.UPLOAD_PAYMENT_RECEIPT),
      map((action: fromActions.UploadPaymentReceipt) => action.request),
      switchMap(request => {
        return from(this.paymentService.uploadPaymentReceiptScreenshot(request.file)).pipe(
          map(screenshotUrl => new fromActions.UploadPaymentReceiptSuccess(screenshotUrl)),
          catchError(err => {
            this.notification.error(err.message);
            return of(new fromActions.UploadPaymentReceiptError(err.message));
          })
        )
      })
    )
  });

}
