import {Injectable} from '@angular/core';
import {DbPath} from "@app/shared/utils/db-path";
import {
  collection,
  doc,
  endBefore,
  Firestore,
  getCountFromServer,
  getDocs,
  increment,
  limit,
  limitToLast,
  orderBy,
  query,
  startAfter,
  startAt,
  updateDoc,
  where
} from "@angular/fire/firestore";
import {GetProductsRequest, SearchProductsRequest} from "@app/store/product-paging";
import {DocumentData, Query} from "rxfire/firestore/interfaces";

@Injectable({
  providedIn: 'root'
})
export class ProductsRepository {

  constructor(private firestore: Firestore) { }

  async decrementProductInventory(merchantId: string, productId: string, quantity: number) {
    const ref =
        doc(this.firestore, `${DbPath.Merchants}/${merchantId}/${DbPath.Products}/${productId}`);
    await updateDoc(ref, {quantity: increment(-quantity)});
  }

  getProductsBaseQuery(merchantId: string, searchText: string) {
    const productCollection =
        collection(this.firestore,
            `${DbPath.Merchants}/${merchantId}/${DbPath.Products}`);

    let productsBaseQuery = query(productCollection,
        where('showOnPage', '==', true),
        orderBy('name', 'asc'),
    );
    if(searchText.length >= 3) {
      productsBaseQuery = query(productsBaseQuery,
          where('trigram', 'array-contains-any', searchText.toLowerCase().split(' ')));
    }
    return productsBaseQuery;
  }

  async getFirstProducts(request: GetProductsRequest, firstProduct: any, searchText: string): Promise<any> {
    const productsBaseQuery = this.getProductsBaseQuery(request.merchantId, searchText);
    const total = (await getCountFromServer(productsBaseQuery)).data().count;

    let productsQuery = query(productsBaseQuery, limit(request.limit));
    if(firstProduct){
      productsQuery = query(productsQuery,
        startAt(firstProduct));
    }
    let productsResult = await this.queryProducts(productsQuery);
    return {
      total,
      ...productsResult
    }
  }

  async getNextProducts(request: GetProductsRequest, lastProduct: any, searchText: string): Promise<any> {
    let productsBaseQuery = this.getProductsBaseQuery(request.merchantId, searchText)
    const total = (await getCountFromServer(productsBaseQuery)).data().count;

    let productsQuery = query(productsBaseQuery,
        startAfter(lastProduct),
        limit(request.limit));
    let productsResult = await this.queryProducts(productsQuery);
    return {
      total,
      ...productsResult
    }
  }

  async getPreviousProducts(request: GetProductsRequest, firstProduct: any, searchText: string): Promise<any> {
    let productsBaseQuery = this.getProductsBaseQuery(request.merchantId, searchText)
    const total = (await getCountFromServer(productsBaseQuery)).data().count;

    let productsQuery = query(productsBaseQuery,
        endBefore(firstProduct),
        limitToLast(request.limit));

    let productsResult = await this.queryProducts(productsQuery);
    return {
      total,
      ...productsResult
    }
  }

    async searchProducts(request: SearchProductsRequest): Promise<any> {
        const productsBaseQuery = this.getProductsBaseQuery(request.merchantId, request.searchText);
        const total = (await getCountFromServer(productsBaseQuery)).data().count;

        let productsQuery = query(productsBaseQuery, limit(request.limit));

        let productsResult = await this.queryProducts(productsQuery);
        return {
            total,
            ...productsResult
        }
    }

  async queryProducts(productsQuery: Query<DocumentData>) {
    const productSnapshots = await getDocs(productsQuery);

    const nextFirstProduct = Object.freeze(productSnapshots.docs[0]);
    const nextLastProduct = Object.freeze(productSnapshots.docs[productSnapshots.docs.length - 1]);
    const products = productSnapshots.docs
        .map((doc) =>
            ({id: doc.id, ...doc.data()})
        );
    return {
      firstProduct: nextFirstProduct,
      lastProduct: nextLastProduct,
      products
    };
  }
}
