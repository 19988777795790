import { Injectable } from '@angular/core';
import {
  collection, endBefore,
  Firestore,
  getCountFromServer, getDocs,
  limit, limitToLast,
  orderBy,
  query,
  startAfter, startAt,
  where
} from "@angular/fire/firestore";
import {DbPath} from "@app/shared/utils/db-path";
import {DocumentData, Query} from "rxfire/firestore/interfaces";
import {Constants} from "@app/shared/utils/constant";
import {GetPostsRequest, SearchPostsRequest} from "@app/store/posts-paging/posts-paging.models";

@Injectable({
  providedIn: 'root'
})
export class PostsRepository {

  constructor(private firestore: Firestore) { }

  getPostsBaseQuery(merchantId: string, trigram: string) {
    const postCollection =
        collection(this.firestore,
            `${DbPath.Merchants}/${merchantId}/${DbPath.Posts}`);

    let postsBaseQuery = query(postCollection,
        where('available', '==', true),
        where('type', '==', Constants.POST_TYPE.ADVANCE),
        orderBy('title', 'asc')
    );
    if(trigram.length >= 3) {
      postsBaseQuery = query(postsBaseQuery,
          where('trigram', 'array-contains-any', trigram.toLowerCase().split(' ')));
    }
    return postsBaseQuery
  }

  async getFirstPosts(request: GetPostsRequest, firstPost: any, searchText: string): Promise<any> {
    const postsBaseQuery = this.getPostsBaseQuery(request.merchantId, searchText);
    const total = (await getCountFromServer(postsBaseQuery)).data().count;

    let postsQuery = query(postsBaseQuery, limit(request.limit));
    if(firstPost){
      postsQuery = query(postsQuery,
          startAt(firstPost));
    }
    let postsResult = await this.queryPosts(postsQuery);

    return {
      total,
      ...postsResult
    }
  }

  async getNextPosts(request: GetPostsRequest, lastPost: any, searchText: string): Promise<any> {
    let postsBaseQuery = this.getPostsBaseQuery(request.merchantId, searchText)
    const total = (await getCountFromServer(postsBaseQuery)).data().count;

    let postsQuery = query(postsBaseQuery,
        startAfter(lastPost),
        limit(request.limit));

    let postsResult = await this.queryPosts(postsQuery);
    return {
      total,
      ...postsResult
    }
  }

  async getPreviousPosts(request: GetPostsRequest, firstPost: any, searchText: string): Promise<any> {
    let postsBaseQuery = this.getPostsBaseQuery(request.merchantId, searchText)
    const total = (await getCountFromServer(postsBaseQuery)).data().count;

    let postsQuery = query(postsBaseQuery,
        endBefore(firstPost),
        limitToLast(request.limit));

    let postsResult = await this.queryPosts(postsQuery);
    return {
      total,
      ...postsResult
    }
  }

  async searchPosts(request: SearchPostsRequest): Promise<any> {
    const postsBaseQuery = this.getPostsBaseQuery(request.merchantId, request.searchText);
    const total = (await getCountFromServer(postsBaseQuery)).data().count;

    let postsQuery = query(postsBaseQuery, limit(request.limit));
    let postsResult = await this.queryPosts(postsQuery);

    return {
      total,
      ...postsResult
    }
  }

  async queryPosts(postsQuery: Query<DocumentData>) {
    const postSnapshots = await getDocs(postsQuery);

    const nextFirstPost = Object.freeze(postSnapshots.docs[0]);
    const nextLastPost= Object.freeze(postSnapshots.docs[postSnapshots.docs.length - 1]);
    const posts = postSnapshots.docs
        .map((doc) =>
            ({id: doc.id, ...doc.data()})
        );
    return {
      firstPost: nextFirstPost,
      lastPost: nextLastPost,
      posts
    };
  }
}
