import { PostsService } from "@app/services/posts.service";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import * as PostActions from './posts.actions';
import { catchError, from, map, mergeMap, of } from "rxjs";
import { Injectable } from "@angular/core";

@Injectable()
export class PostEffects {
    constructor(
        private action$: Actions,
        private postService: PostsService,
    ) {}

    listPost$ = createEffect(() => this.action$.pipe(
        ofType(PostActions.listPosts),
        mergeMap(({ merchantId }) =>
            from(this.postService.listPosts(merchantId)).pipe(
                map(posts => PostActions.listPostsSuccess({posts: posts})),
                catchError(error => of(PostActions.listPostsFailure({ error })))
            )
        )
    ))

    getPost$ = createEffect(() => this.action$.pipe(
        ofType(PostActions.getPost),
        mergeMap(({ getPost }) =>
            from(this.postService.getPost(getPost)).pipe(
                map(post => PostActions.getPostSuccess({post: post})),
                catchError(error => of(PostActions.getPostFailure({ error })))
            )
        )
    ))

    searchPost$ =  createEffect(() => this.action$.pipe(
        ofType(PostActions.searchPost),
        mergeMap(({ searchPost }) =>
            from(this.postService.searchPost(searchPost)).pipe(
                map(posts => PostActions.searchPostSuccess({posts: posts})),
                catchError(error => of(PostActions.searchPostFailure({ error })))
            )
        )
    ))

    readElements$ = createEffect(() => this.action$.pipe(
        ofType(PostActions.readElements),
        mergeMap(({ readElements }) =>
            from(this.postService.readElements(readElements)).pipe(
                map(elements => PostActions.readElementsSuccess({ elements: elements })),
                catchError(error => of(PostActions.readElementsFailure(error)))
            )
        )
    ))
}
